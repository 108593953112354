@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i');


	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: ( gutters: 1.5em )
	));

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-form($p);
		@include color-list($p);
		@include color-section($p);
		@include color-table($p);
	}

// Base.

	@import 'base/page';
	@import 'base/typography';
	@import 'base/fa-regular.min';

// Component.

	@import 'components/box';
	@import 'components/button';
	@import 'components/faq';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/section';
	@import 'components/table';
	@import 'components/posts';
	@import 'components/team';
	@import 'components/wrapper';
	@import 'components/carousel';
	@import 'components/spotlights';
	@import 'components/testimonials';
	@import 'components/zoom';

// Layout.

	@import 'layout/header';
	@import 'layout/menu';
	@import 'layout/banner';
	@import 'layout/main';
	@import 'layout/footer';