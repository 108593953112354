/* Header */

	#header {
		@include vendor('display', 'flex');
		@include vendor('justify-content', 'space-between');
		@include vendor('align-items', 'center');
		@include vendor('transition', 'background-color #{_duration(transition)} ease');
		background-color: _palette(bg);
		box-shadow: 0 0 0.5rem 0 _palette(border);
		height: 4.5rem;
		left: 0;
		line-height: 4.5rem;
		padding: 0 0 0 1.5rem;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base);

		a {
			color: _palette(fg-bold);
			font-size: 1.1rem;
			text-decoration: none;
			text-transform: uppercase;
			border: 0;

			&:active, &:hover, &:focus {
				color: _palette(accent1, bg);
			}
		}

		.logo {
			display: block;
			font-weight: _font(weight-bold);
			height: inherit;
			letter-spacing: 0.07rem;
			line-height: inherit;
			padding: 5px 0;
			width:160px;

			span {
				font-weight: _font(weight);
			}

		}

		nav {
			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					display: inline-block;
					padding: 0;

					a {
						display: inline-block;
						padding: 0 1.5rem 0 1.5rem;
						position: relative;

						&[href="#menu"] {
							@include icon;
							padding-right: 3rem;

							&:before {
								content: '\f0c9'; // Hamburger icon;
								display: block;
								height: 100%;
								position: absolute;
								right: 1.5rem;
								text-align: center;
								top: 0;
								width: 1rem;
							}
						}
					}
				}
			}
		}

		&.alt {
			background-color: transparent;
			box-shadow: none;
			color: _palette(bg);

			.logofill {
				fill:#fff !important;
			}

			a {
				color:#fff;
			}
		}

		@include breakpoint(small) {
			height: 2.75rem;
			line-height: 2.75rem;
			padding: 0 0 0 0.75rem;

			nav {
				ul {
					li {
						a {
							padding: 0 0.75rem 0 0.75rem;

							&[href="#menu"] {
								padding-right: 2.5rem;

								&:before {
									right: 1rem;
								}
							}
						}
					}
				}
			}
		}

		@include breakpoint(xsmall) {
			.logo {
				span {
					display: none;
				}
			}

			nav {
				ul {
					li {
						a {
							&[href="#menu"] {
								&:only-child {
									width: 4rem;
									text-indent: 4rem;
									white-space: nowrap;

									&:before {
										text-indent: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}