/* Menu */

	#menu {
		@include color-typography(accent1);
		@include color-button(accent1);
		@include vendor('transform', 'translateX(20rem)');
		@include vendor('transition', ('transform #{_duration(menu)} ease', 'box-shadow #{_duration(menu)} ease', 'visibility #{_duration(menu)}'));
		-webkit-overflow-scrolling: touch;
		background: desaturate(darken(_palette(accent1, bg), 5), 5);
		box-shadow: none;
		height: 100%;
		max-width: 80%;
		overflow-y: auto;
		padding: 3rem 2rem;
		position: fixed;
		right: 0;
		top: 0;
		visibility: hidden;
		width: 20rem;
		z-index: _misc(z-index-base) + 2;

		> ul {
			margin: 0 0 (_size(element-margin) * 0.5) 0;

			&.links {
				list-style: none;
				padding: 0;

				> li {
					padding: 0;

					> a:not(.button) {
						border: 0;
						border-top: solid 1px _palette(accent1, border);
						color: inherit;
						display: block;
						letter-spacing: _size(letter-spacing-alt);
						line-height: 3.5rem;
						text-decoration: none;

						&:hover {
							color:#fff;
						}

					}

					> .button {
						display: block;
						margin: 1.25rem 0 0 0;
					}

					&:first-child {
						> a:not(.button) {
							border-top: 0 !important;
						}
					}

					ul {
						list-style: none;
						li {
							&:before {
								font: normal normal normal 14px/1 Font Awesome\ 5 Pro;
								content: "\f0da";
							}
							> a {
								color:inherit;
								text-decoration: none;

								&:hover {
									color:#fff;
								}
							}
						}
					}
				}
			}
		}

		.close {
			@include icon;
			@include vendor('transition', 'color #{_duration(transition)} ease-in-out');
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			border: 0;
			color: #fff;
			cursor: pointer;
			display: block;
			height: 3.25rem;
			line-height: 3.25rem;
			padding-right: 1.25rem;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;
			vertical-align: middle;
			width: 7rem;

			&:before {
				content: '\f00d';
				font-size: 1.25rem;
			}

			&:hover {
				color: _palette(accent2, fg-bold);
			}

			@include breakpoint(small) {
				height: 4rem;
				line-height: 4rem;
			}
		}

		&.visible {
			@include vendor('transform', 'translateX(0)');
			box-shadow: 0 0 1.5rem 0 rgba(0,0,0,0.2);
			visibility: visible;
		}

		@include breakpoint(small) {
			padding: 2.5rem 1.75rem;
		}
	}