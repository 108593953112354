/* Testimonials */

	.t-quote {
		font-size:200px;
		line-height: 0.3;

		@include breakpoint(medium) {
			font-size: 80px;
			line-height: 0.5;
		}

	}

	.t-name {
		font-style: italic;
	}

	.testimonial {
		display:flex;
		position: relative;
		color:#fff;

		@include breakpoint(small) {
			flex-direction: column;
		}


		&.even {
				@include vendor('flex-direction', 'row-reverse');
				text-align: right;
		}

		.content {
			flex:1;
			padding:60px;

			@include breakpoint(medium) {
					padding: 20px;
			}


			p {
				font-size:18px;
			}

		}

		h3 {
			color:#fff;
		}

		.testimonial-image {
			flex-basis: 45%;
			background-position: center;
			background-size: cover;

			@include breakpoint(small) {
				min-height: 300px;
			}
			
		}

		@each $accent in ('accent1', 'accent2', 'accent3', 'accent4') {
			&.#{$accent} {
				background-color: _palette($accent, bg);

				h2 {
					color: _palette($accent, fg-bold);
				}
			}
		}

	}
