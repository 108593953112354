/* Main */

.dontlikeyou {
	display: none;
}

.cc-compliance {
	a {
		text-decoration: none;
	}
}

.mc-flex {
	display: flex;

	.flex-input {
		flex-basis: 30%;
		margin-right: 20px;

		@include breakpoint(medium) {
			flex-basis: 70%;
		}

		@include breakpoint(small) {
			flex: 1;
		}

		input {
			color: #00BEE6;
		}
	}

	input[type="submit"] {
		padding: 0 2.5em;
	}

	@include breakpoint(small) {
		input[type="submit"] {
			padding: 0 1em;
		}
	}
}

.mc-privacy {
	font-size: 10px;

	a {
		color: #fff !important;
	}
}

	#main {
		padding-top: 7rem;

		@include breakpoint(medium) {
			padding-top: 6rem;
		}

		@include breakpoint(small) {
			padding-top: 4rem;
		}
	}

.clear {
	clear:both;
}

.image-flex {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-right: -4px;

	.image-child {
		flex:1 1 30%;
		margin-bottom:4px;
		margin-right:4px;

		img {
			max-width: 100%;
			vertical-align: middle;
		}
	}
}