///
/// Cascade by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Wrapper */

	.wrapper {
		@include padding(6rem, 0);
		position: relative;
		overflow-x: hidden;

		> .inner {
			margin: 0 auto;
			max-width: _size(container);
			width: calc(100% - 6rem);
		}

		&.style1 {
			@include color(accent1);
		}

		&.style2 {
			@include color(accent2);
		}

		&.style3 {
			@include color(accent3);
		}

		&.style4 {
			@include color(accent4);
		}

		@include breakpoint(large) {
			@include padding(5rem, 0);
		}

		@include breakpoint(medium) {
			@include padding(4rem, 0);
		}

		@include breakpoint(small) {
			@include padding(3rem, 0);

			> .inner {
				width: calc(100% - 3rem);
			}
		}
	}