/* Team */

	.team {
		display:flex;
		margin-bottom: (_size(element-margin) * 1.5);
		flex-wrap: wrap;
		justify-content: space-between;

		article {
			text-align: center;
			flex-basis: 31%;
			box-shadow: 0 0 1rem rgba(#000, 0.1);
			margin-bottom: (_size(element-margin) * 1.5);
		}

		.image {
			display: block;
			max-width: 100%;

			img {
				width: 100%;
			}
		}

		.content {
			@include padding(3rem, 3rem);
		}

		@include breakpoint(medium) {
			article {
				flex-basis: 48%;
			}

			.content {
				@include padding(2.5rem, 2rem);
			}
		}

		@include breakpoint(xsmall) {
			article {
				flex-basis: 100%;
			}

			.content {
				@include padding(2rem, 1.5rem);
			}
		}
	}