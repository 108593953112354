///
/// Cascade by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* List */

	.inner {
		ul {
			list-style: none;
			margin: 0 0 _size(element-margin) 0;
			padding-left: 1rem;
	
			li {
				padding-left: 1.1rem;
				background-image: url(../img/cog.svg);
				background-position: left 8px;
				background-repeat: no-repeat;
				background-size: 12px 12px;
			}
		}
	}

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25rem;

		li {
			padding-left: 0.25rem;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1rem;

		li {
			padding-left: 0.5rem;
		}

		&.alt {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px;
				padding: 0.5rem 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 1rem 0 0;

				&:last-child {
					padding-right: 0;
				}

				.icon {
					&:before {
						font-size: 2rem;
					}
				}
			}
		}

		&.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 (_size(element-margin) * 0.5) 0 0;
				vertical-align: middle;
				background: none;

				&:last-child {
					padding-right: 0;
				}
			}

			&.small {
				li {
					padding: 0 (_size(element-margin) * 0.25) 0 0;
				}
			}

			&.vertical {
				li {
					display: block;
					padding: (_size(element-margin) * 0.5) 0 0 0;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}

			&.fit {
				display: table;
				margin-left: (_size(element-margin) * -0.5);
				padding: 0;
				table-layout: fixed;
				width: calc(100% + #{(_size(element-margin) * 0.5)});

				li {
					display: table-cell;
					padding: 0 0 0 (_size(element-margin) * 0.5);

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					margin-left: (_size(element-margin) * -0.25);
					width: calc(100% + #{(_size(element-margin) * 0.25)});

					li {
						padding: 0 0 0 (_size(element-margin) * 0.25);
					}
				}
			}

			@include breakpoint(xsmall) {
				margin: 0 0 _size(element-margin) 0;

				li {
					padding: (_size(element-margin) * 0.5) 0 0 0;
					display: block;
					text-align: center;
					width: 100%;

					&:first-child {
						padding-top: 0;
					}

					> * {
						width: 100%;
						margin: 0 !important;

						&.icon {
							&:before {
								margin-left: -2rem;
							}
						}
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}
		}

		&.contact-icons {
			list-style: none;
			padding-left: 0;

			li {
				&:before {
					padding-left: 0;
					margin-right: 1em;
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;

		dt {
			display: block;
			font-weight: _font(weight-bold);
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		dd {
			margin-left: _size(element-margin);
		}
	}

	@mixin color-list($p: null) {
		ul {
			&.alt {
				li {
					border-top-color: _palette($p, border);
				}
			}
		}
	}

	@include color-list;

.side-by-side {
	display: flex;
	justify-content: space-between;

	.leftside {
		flex-basis: 48%;
	}

	.right-side {
		flex-basis: 48%;
	}

	@include breakpoint(medium) {
		display: block;
	}
}

.available-dates {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.date-child {
		flex-basis: 30%;
		background: #f2f2f2;
		padding: 40px 60px;
		margin-bottom: 40px;
		text-align: center;
		position: relative;
		overflow: hidden;

		&::before {
			content: '\f073';
			font-family: Font Awesome\ 5 Pro;
			position: absolute;
			top: 0;
			left: -15px;
			font-size:60px;
			color:#ddd;
		}

		@include breakpoint(medium) {
			flex-basis: 48%;
		}
	}

	@include breakpoint(xsmall) {
		display: block;
	}
}

.price-per-person {
	background: #FD81B1;
	color: #fff;
	padding: 20px;
	text-align: center;
	position: relative;
	overflow: hidden;

	&::before {
		font-family: Font Awesome\ 5 Pro;
		position: absolute;
		top: -5px;
		left: -10px;
		font-size:40px;
		color:#fff;
		content: '\f154';
	}
}

.extra-prices {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.extra-prices-child {
		flex-basis: 48%;
		padding: 20px 40px;
		background: #00BEE6;
		text-align: center;
		color: #fff;
		margin-top: 40px;
		position: relative;
		overflow: hidden;
	}

	.supplement-fee,
	.bike-hire,
	.e-bike,
	.maximum-people {

		&::before {
			font-family: Font Awesome\ 5 Pro;
			position: absolute;
			top: -5px;
			left: -10px;
			font-size:40px;
			color:#fff;
		}
	}

	.supplement-fee {

		&::before {
			content: '\f007';
		}
	}

	.bike-hire {

		&::before {
			content: '\f206';
		}
	}

	.e-bike {

		&::before {
			content: '\f21c';
		}
	}

	.maximum-people {

		&::before {
			content: '\f0c0';
		}
	}

	@include breakpoint(xsmall) {
		display: block;
	}
}

