
/* Form */

	.contact-parent {
		display: flex;

		.contact-details {
			flex-basis: 50%;
			background: #fd9ac1;
			padding:30px;
			color:#fff;

			h3 {
				color:#fff;
			}

			a {
				color:#fff;
			}

		}

		.contact-form {
			flex-basis: 50%;
			padding:30px;
			background: #f2f2f2;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				border-top: 30px solid #fff;
				border-left: 30px solid #f2f2f2;
				width: 0;
			}

		}

	}

	form {
		margin: 0 0 _size(element-margin) 0;

		header.major + & {
			margin-top: -1.5rem;
		}

		&.combined {
			@include vendor('display', 'flex');
			@include vendor('flex-direction', 'row');
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
			position: relative;
			width: 30rem;

			input[type="text"],
			input[type="password"],
			input[type="email"],
			select,
			textarea {
				@include vendor('flex-grow', '1');
				@include vendor('flex-shrink', '1');
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			}

			input[type="submit"] {
				@include vendor('flex-grow', '0');
				@include vendor('flex-shrink', '0');
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

		@include breakpoint(xsmall) {
			&.combined {
				@include vendor('flex-direction', 'column');

				input[type="text"],
				input[type="password"],
				input[type="email"],
				select,
				textarea {
					border-radius: _size(border-radius);
					margin: 0 0 1em 0;
				}

				input[type="submit"] {
					border-radius: _size(border-radius);
				}
			}
		}
	}

	label {
		display: block;
		font-size: 0.9rem;
		font-weight: _font(weight-bold);
		margin: 0 0 (_size(element-margin) * 0.5) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		border-radius: _size(border-radius);
		border: solid 3px;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1rem;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			content: '\f078';
			display: block;
			height: _size(element-height);
			line-height: _size(element-height);
			pointer-events: none;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: _size(element-height);
		}

		select::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0.75rem 1rem;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		@each $accent in ('accent1', 'accent2', 'accent3', 'accent4') {
			&.#{$accent}:focus {
				border-color: _palette($accent, bg) !important;
			}
		}
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2rem;
		opacity: 0;
		width: 1rem;
		z-index: -1;

		& + label {
			@include icon;
			cursor: pointer;
			display: inline-block;
			font-size: 1rem;
			font-weight: _font(weight);
			padding-left: (_size(element-height) * 0.6) + 0.75rem;
			padding-right: 0.75rem;
			position: relative;

			&:before {
				border-radius: _size(border-radius);
				border: solid 3px;
				content: '';
				display: inline-block;
				height: (_size(element-height) * 0.6);
				left: 0;
				line-height: (_size(element-height) * 0.575);
				position: absolute;
				text-align: center;
				top: 0;
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				content: '\f00c';
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: _size(border-radius);
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		opacity: 1.0;
	}

	:-moz-placeholder {
		opacity: 1.0;
	}

	::-moz-placeholder {
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		opacity: 1.0;
	}

	@mixin color-form($p: null) {
		$highlight: _palette($p, highlight);

		label {
			color: _palette($p, fg-bold);
		}

		input[type="text"],
		input[type="password"],
		input[type="email"],
		select,
		textarea {
			background: #fff;
			border-color: _palette($p, border);

			&:focus {
				@if $p == $highlight {
					border-color: _palette($p, fg-bold);
				}
				@else {
					border-color: _palette(accent1, bg);
				}
			}

			&.alt {
				background-color: _palette($p, fg-bold);
				border-color: _palette($p, fg-bold);
				color: _palette($p, bg);

				&:focus {
					border-color: _palette($p, fg-light);
				}
			}
		}

		.select-wrapper {
			&:before {
				color: _palette($p, border);
			}
		}

		input[type="checkbox"],
		input[type="radio"], {
			& + label {
				color: _palette($p, fg);

				&:before {
					background: _palette($p, border-bg);
					border-color: _palette($p, border);
				}
			}

			&:checked + label {
				&:before {
					@if $p == $highlight {
						background-color: _palette($p, fg-bold);
						border-color: _palette($p, fg-bold);
						color: _palette($p, bg);
					}
					@else {
						background-color: _palette(accent1, bg);
						border-color: _palette(accent1, bg);
						color: _palette(accent1, fg-bold);
					}
				}
			}

			&:focus + label {
				&:before {
					@if $p == $highlight {
						border-color: _palette($p, fg-bold);
					}
					@else {
						border-color: _palette(accent1, bg);
					}
				}
			}
		}

		::-webkit-input-placeholder {
			color: _palette($p, fg-light) !important;
		}

		.alt::-webkit-input-placeholder {
			color: _palette(fg-light) !important;
		}

		:-moz-placeholder {
			color: _palette($p, fg-light) !important;
		}

		.alt:-moz-placeholder {
			color: _palette(fg-light) !important;
		}

		::-moz-placeholder {
			color: _palette($p, fg-light) !important;
		}

		.alt::-moz-placeholder {
			color: _palette(fg-light) !important;
		}

		:-ms-input-placeholder {
			color: _palette($p, fg-light) !important;
		}

		.alt:-ms-input-placeholder {
			color: _palette(fg-light) !important;
		}
	}

	@include color-form;