/* Banner */

	#banner {
		@include padding(6rem, 8%);
		@include vendor('align-items', 'center');
		@include vendor('display', 'flex');
		@include vendor('justify-content', 'center');
		@include color-typography(alt);
		@include color-button(alt);
		background-position:	center;
		background-size:		cover;
		background-attachment:	fixed;
		cursor: default;
		min-height: 100vh;
		position: relative;
		text-align: center;

		&:after {
			@include vendor('transition', 'opacity 1s ease-in-out');
			@include vendor('transition-delay', '0.5s');
			content: '';
			background-color: rgba(0,0,0,0.4);
			/*background-color: _palette(alt, bg);*/
			opacity: 0.35;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.inner {
			@include vendor('transition', 'opacity 1s ease-in-out');
			position: relative;
			max-width: 35rem;
			z-index: 1;
		}

		h1 {
			line-height: 1.3;

			&:after {
				content: '';
				background-color: _palette(alt, border);
				display: block;
				height: 3px;
				margin: 2rem auto;
				width: 4rem;
			}
		}

		p {
			margin-bottom: 2.5rem;
		}

		body.is-ie & {
			height: 100vh;
		}

		@include breakpoint(large) {
			background-attachment: scroll;

			h1 {
				font-size: 3.5rem;
			}
		}

		@include breakpoint(small) {
			h1 {
				font-size: 2.5rem;

				br {
					display: none;
				}
			}
		}

		body.is-loading & {
			&:after {
				opacity: 1;
			}

			.inner {
				opacity: 0;
			}
		}
	}