/* FAQ */
	.faq-box {
		margin-bottom: 4rem;

		h4 {
			border-bottom: 1px solid #f1f1f1;
			padding-bottom: 30px;
			margin-bottom: 0;
			&:after {
				border-bottom:5px solid _palette(accent1, bg);
				width:40px;
				content: '';
				display: block;
				padding-top:20px;
			}
		}

		.answer {
			padding:30px;	
		}
	}

	.faq-wrap {
		border-bottom: 1px solid #f1f1f1;
	}

	.question {
		font-size:1.2rem;
		cursor: pointer;
		padding: 10px 10px;

		&:after {
				content: "\f107";
				font-family: 'Font Awesome\ 5 Pro';
				font-weight:normal;
				margin-right:10px;
				speak: none;
				float: right;
				color: _palette(accent1, bg);
		}

		
	}

	.question.close {

		&:after {
			content: "\f106";
		}

	}