///
/// Cascade by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out, color #{_duration(transition)} ease-in-out');
		border-radius: _size(border-radius);
		border: 0;
		cursor: pointer;
		display: inline-block;
		font-weight: _font(weight-bold);
		font-size: 0.85rem;
		height: 3rem;
		letter-spacing: 0.04rem;
		line-height: 3rem;
		padding: 0 2.5em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;

		&.icon {
			&:before {
				margin-right: 0.5rem;
			}
		}

		&.fit {
			display: block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			width: 100%;
		}

		&.small {
			font-size: 0.7rem;
			height: 2.2rem;
			line-height: 2.2rem;
		}

		&.big {
			font-size: 1.1rem;
			height: 4.25rem;
			line-height: 4.25rem;
		}

		&.wide {
			min-width: 15em;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}

		@each $accent in ('accent1', 'accent2', 'accent3', 'accent4') {
			&.special.#{$accent} {
				background-color: _palette($accent, bg) !important;
				color: _palette($accent, fg-bold) !important;

				&:hover, &:focus {
					background-color: lighten(_palette($accent, bg), 5) !important;
				}

				&:active {
					background-color: darken(_palette($accent, bg), 5) !important;
				}
			}
		}

		@include breakpoint(xsmall) {
			padding: 0;
		}
	}

	@mixin color-button($p: null) {
		$highlight: _palette($p, highlight);

		input[type="submit"],
		input[type="reset"],
		input[type="button"],
		button,
		.button {
			background-color: transparent;
			box-shadow: inset 0 0 0 3px _palette($p, border);
			color: _palette($p, fg-bold);

			&:hover, &:focus {
				background-color: _palette($p, border-bg);
			}

			&:active {
				background-color: opacify(_palette($p, border-bg), 0.1);
			}

			&.icon {
				&:before {
					color: _palette($p, fg-light);
				}
			}

			&.special {
				box-shadow: none;

				@if $p == $highlight {
					background-color: _palette($p, fg-bold);
					color: _palette($p, bg);

					&.icon {
						&:before {
							color: _palette($p, bg);
						}
					}
				}
				@else {
					background-color: _palette($highlight, bg);
					color: _palette($highlight, fg-bold);

					&.icon {
						&:before {
							color: _palette($highlight, fg-bold);
						}
					}

					&:hover, &:focus {
						background-color: lighten(_palette($highlight, bg), 5);
					}

					&:active {
						background-color: darken(_palette($highlight, bg), 5);
					}
				}
			}
		}
	}

	@include color-button;