///
/// Cascade by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Section/Article */

	section, article {
		&.special {
			text-align: center;
		}
	}

	.major-hero {
		position: relative;
		margin-bottom: 5rem;

		&:before {
			background-image: url('../img/top-corner.svg');
			width: 200px;
			display: block;
			height: 200px;
			content: '';
			right: -17px;
			position: absolute;
			top: -17px;

			@include breakpoint(medium) {
				width:100px;
				height:100px;
				top: -9px;
				right: -9px;
			}

			@include breakpoint(medium) {
				width:50px;
				height:50px;
				top: -4px;
				right: -4px;
			}
		}

		h1 {
			position: absolute;
			bottom: -2rem;
			left: -2rem;
			background:#fff;
			padding:30px;
			box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
			margin-bottom: 0;
			z-index: 1;

			@include breakpoint(medium) {
				padding:15px;
				bottom: -3rem;
			}

			@include breakpoint(small) {
				font-size:26px;
				left: -1rem;
			}
		}

		img {
			max-width: 100%;
			vertical-align: middle;
		}

	}

	header {

		p {
			max-width: 50rem;
			position: relative;
			margin-left: auto;
			margin-right: auto;
		}

		h1 + p,
		h2 + p,
		h3 + p {
			margin-top: (_size(element-margin) * -0.55);
		}

		h4 + p,
		h5 + p,
		h6 + p {
			font-size: 0.9rem;
			margin-top: (_size(element-margin) * -0.3);
		}

		&.major {
			margin-bottom: 4rem;
			text-align: center;

			h1, h2 {
				&:after {
					background-color: currentColor;
					content: '';
					display: block;
					height: 3px;
					margin: 1.5rem auto;
					width: 4rem;
				}
			}

			p {
				margin-top: 0;
			}

			@include breakpoint(large) {
				margin-bottom: 3.5rem;
			}

			@include breakpoint(small) {
				margin-bottom: 2rem;
			}
		}
	}

	@mixin color-section($p: null) {
		header {
			p {
				color: _palette($p, fg-light);
			}
		}
	}

	@include color-section;