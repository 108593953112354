// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.5s,
		transition:			0.2s
	);

// Size.
	$size: (
		border-radius:		4px,
		container: 			75rem,
		element-height:		3rem,
		element-margin:		2.5rem
	);

// Font.
	$font: (
		family:				('Open Sans', Arial, Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				400,
		weight-bold:		700,
		weight-light: 		300,
		kerning-heading:	0.05em
	);

// Palette.
	$palette: (
		bg:					#fff,
		fg:					#888,
		fg-bold:			#666666,
		fg-light:			#bbb,
		border:				rgba(144,144,144,0.25),
		border-bg:			rgba(144,144,144,0.075),
		border2:			rgba(144,144,144,0.5),
		border2-bg:			rgba(144,144,144,0.2),
		highlight:			accent1,

		accent1: (
			bg:				#FD81B1,
			fg:				mix(#FD81B1, #ffffff, 25%),
			fg-bold:		#ffffff,
			fg-light:		mix(#FD81B1, #ffffff, 40%),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent2: (
			bg:				#00BEE6,
			fg:				mix(#00BEE6, #ffffff, 25%),
			fg-bold:		#ffffff,
			fg-light:		mix(#00BEE6, #ffffff, 40%),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent3: (
			bg:				#594095,
			fg:				mix(#594095, #ffffff, 25%),
			fg-bold:		#ffffff,
			fg-light:		mix(#594095, #ffffff, 40%),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent4: (
			bg:				#6E81D0,
			fg:				mix(#6E81D0, #ffffff, 25%),
			fg-bold:		#ffffff,
			fg-light:		mix(#6E81D0, #ffffff, 40%),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		alt: (
			bg:				#FD81B1,
			fg:				#ffffff,
			fg-bold:		#ffffff,
			fg-light:		#ffffff,
			border:			#ffffff,
			border-bg:		rgba(255,255,255,0.075),
			border2:		#ffffff,
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		)
	);