/* Posts */

	.posts {
		@include flexgrid((
			columns: 2,
			gutters: 3rem
		));
		margin-bottom: (_size(element-margin) * 1.5);

		article {
			text-align: center;
		}

		.image {
			display: block;
			width: 100%;

			img {
				width: 100%;
			}
		}

		.content {
			@include padding(3rem, 3rem);
			box-shadow: 0 0 1rem rgba(#000, 0.1);
		}

		@include breakpoint(medium) {
			@include flexgrid-resize((
				columns: 1,
				gutters: 3rem,
				prev-columns: 2,
			));

			.content {
				@include padding(2.5rem, 2rem);
			}
		}

		@include breakpoint(small) {
			@include flexgrid-resize((
				columns: 1,
				gutters: 2rem,
				prev-columns: 2,
			));

			.content {
				@include padding(2rem, 1.5rem);
			}
		}
	}
