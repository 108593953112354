@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i");
/* Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

/* Box Model */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Grid */
.row {
  border-bottom: solid 1px transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.row > * {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.row:after, .row:before {
  content: '';
  display: block;
  clear: both;
  height: 0;
}

.row.uniform > * > :first-child {
  margin-top: 0;
}

.row.uniform > * > :last-child {
  margin-bottom: 0;
}

.row.\30 \25 > * {
  padding: 0 0 0 0em;
}

.row.\30 \25
{
  margin: 0 0 -1px 0em;
}

.row.uniform.\30 \25 > * {
  padding: 0em 0 0 0em;
}

.row.uniform.\30 \25
{
  margin: 0em 0 -1px 0em;
}

.row > * {
  padding: 0 0 0 1.5em;
}

.row {
  margin: 0 0 -1px -1.5em;
}

.row.uniform > * {
  padding: 1.5em 0 0 1.5em;
}

.row.uniform {
  margin: -1.5em 0 -1px -1.5em;
}

.row.\32 00\25 > * {
  padding: 0 0 0 3em;
}

.row.\32 00\25
{
  margin: 0 0 -1px -3em;
}

.row.uniform.\32 00\25 > * {
  padding: 3em 0 0 3em;
}

.row.uniform.\32 00\25
{
  margin: -3em 0 -1px -3em;
}

.row.\31 50\25 > * {
  padding: 0 0 0 2.25em;
}

.row.\31 50\25
{
  margin: 0 0 -1px -2.25em;
}

.row.uniform.\31 50\25 > * {
  padding: 2.25em 0 0 2.25em;
}

.row.uniform.\31 50\25
{
  margin: -2.25em 0 -1px -2.25em;
}

.row.\35 0\25 > * {
  padding: 0 0 0 0.75em;
}

.row.\35 0\25
{
  margin: 0 0 -1px -0.75em;
}

.row.uniform.\35 0\25 > * {
  padding: 0.75em 0 0 0.75em;
}

.row.uniform.\35 0\25
{
  margin: -0.75em 0 -1px -0.75em;
}

.row.\32 5\25 > * {
  padding: 0 0 0 0.375em;
}

.row.\32 5\25
{
  margin: 0 0 -1px -0.375em;
}

.row.uniform.\32 5\25 > * {
  padding: 0.375em 0 0 0.375em;
}

.row.uniform.\32 5\25
{
  margin: -0.375em 0 -1px -0.375em;
}

.\31 2u, .\31 2u\24
{
  width: 100%;
  clear: none;
  margin-left: 0;
}

.\31 1u, .\31 1u\24
{
  width: 91.6666666667%;
  clear: none;
  margin-left: 0;
}

.\31 0u, .\31 0u\24
{
  width: 83.3333333333%;
  clear: none;
  margin-left: 0;
}

.\39 u, .\39 u\24
{
  width: 75%;
  clear: none;
  margin-left: 0;
}

.\38 u, .\38 u\24
{
  width: 66.6666666667%;
  clear: none;
  margin-left: 0;
}

.\37 u, .\37 u\24
{
  width: 58.3333333333%;
  clear: none;
  margin-left: 0;
}

.\36 u, .\36 u\24
{
  width: 50%;
  clear: none;
  margin-left: 0;
}

.\35 u, .\35 u\24
{
  width: 41.6666666667%;
  clear: none;
  margin-left: 0;
}

.\34 u, .\34 u\24
{
  width: 33.3333333333%;
  clear: none;
  margin-left: 0;
}

.\33 u, .\33 u\24
{
  width: 25%;
  clear: none;
  margin-left: 0;
}

.\32 u, .\32 u\24
{
  width: 16.6666666667%;
  clear: none;
  margin-left: 0;
}

.\31 u, .\31 u\24
{
  width: 8.3333333333%;
  clear: none;
  margin-left: 0;
}

.\31 2u\24 + *,
.\31 1u\24 + *,
.\31 0u\24 + *,
.\39 u\24 + *,
.\38 u\24 + *,
.\37 u\24 + *,
.\36 u\24 + *,
.\35 u\24 + *,
.\34 u\24 + *,
.\33 u\24 + *,
.\32 u\24 + *,
.\31 u\24 + * {
  clear: left;
}

.\-11u {
  margin-left: 91.6666666667%;
}

.\-10u {
  margin-left: 83.3333333333%;
}

.\-9u {
  margin-left: 75%;
}

.\-8u {
  margin-left: 66.6666666667%;
}

.\-7u {
  margin-left: 58.3333333333%;
}

.\-6u {
  margin-left: 50%;
}

.\-5u {
  margin-left: 41.6666666667%;
}

.\-4u {
  margin-left: 33.3333333333%;
}

.\-3u {
  margin-left: 25%;
}

.\-2u {
  margin-left: 16.6666666667%;
}

.\-1u {
  margin-left: 8.3333333333%;
}

@media screen and (max-width: 1680px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row {
    margin: 0 0 -1px -1.5em;
  }
  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }
  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }
  .row.\32 00\25 > * {
    padding: 0 0 0 3em;
  }
  .row.\32 00\25
{
    margin: 0 0 -1px -3em;
  }
  .row.uniform.\32 00\25 > * {
    padding: 3em 0 0 3em;
  }
  .row.uniform.\32 00\25
{
    margin: -3em 0 -1px -3em;
  }
  .row.\31 50\25 > * {
    padding: 0 0 0 2.25em;
  }
  .row.\31 50\25
{
    margin: 0 0 -1px -2.25em;
  }
  .row.uniform.\31 50\25 > * {
    padding: 2.25em 0 0 2.25em;
  }
  .row.uniform.\31 50\25
{
    margin: -2.25em 0 -1px -2.25em;
  }
  .row.\35 0\25 > * {
    padding: 0 0 0 0.75em;
  }
  .row.\35 0\25
{
    margin: 0 0 -1px -0.75em;
  }
  .row.uniform.\35 0\25 > * {
    padding: 0.75em 0 0 0.75em;
  }
  .row.uniform.\35 0\25
{
    margin: -0.75em 0 -1px -0.75em;
  }
  .row.\32 5\25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.\32 5\25
{
    margin: 0 0 -1px -0.375em;
  }
  .row.uniform.\32 5\25 > * {
    padding: 0.375em 0 0 0.375em;
  }
  .row.uniform.\32 5\25
{
    margin: -0.375em 0 -1px -0.375em;
  }
  .\31 2u\28xlarge\29, .\31 2u\24\28xlarge\29
{
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28xlarge\29, .\31 1u\24\28xlarge\29
{
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28xlarge\29, .\31 0u\24\28xlarge\29
{
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28xlarge\29, .\39 u\24\28xlarge\29
{
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28xlarge\29, .\38 u\24\28xlarge\29
{
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28xlarge\29, .\37 u\24\28xlarge\29
{
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28xlarge\29, .\36 u\24\28xlarge\29
{
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28xlarge\29, .\35 u\24\28xlarge\29
{
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28xlarge\29, .\34 u\24\28xlarge\29
{
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28xlarge\29, .\33 u\24\28xlarge\29
{
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28xlarge\29, .\32 u\24\28xlarge\29
{
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28xlarge\29, .\31 u\24\28xlarge\29
{
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28xlarge\29 + *,
  .\31 1u\24\28xlarge\29 + *,
  .\31 0u\24\28xlarge\29 + *,
  .\39 u\24\28xlarge\29 + *,
  .\38 u\24\28xlarge\29 + *,
  .\37 u\24\28xlarge\29 + *,
  .\36 u\24\28xlarge\29 + *,
  .\35 u\24\28xlarge\29 + *,
  .\34 u\24\28xlarge\29 + *,
  .\33 u\24\28xlarge\29 + *,
  .\32 u\24\28xlarge\29 + *,
  .\31 u\24\28xlarge\29 + * {
    clear: left;
  }
  .\-11u\28xlarge\29
{
    margin-left: 91.6666666667%;
  }
  .\-10u\28xlarge\29
{
    margin-left: 83.3333333333%;
  }
  .\-9u\28xlarge\29
{
    margin-left: 75%;
  }
  .\-8u\28xlarge\29
{
    margin-left: 66.6666666667%;
  }
  .\-7u\28xlarge\29
{
    margin-left: 58.3333333333%;
  }
  .\-6u\28xlarge\29
{
    margin-left: 50%;
  }
  .\-5u\28xlarge\29
{
    margin-left: 41.6666666667%;
  }
  .\-4u\28xlarge\29
{
    margin-left: 33.3333333333%;
  }
  .\-3u\28xlarge\29
{
    margin-left: 25%;
  }
  .\-2u\28xlarge\29
{
    margin-left: 16.6666666667%;
  }
  .\-1u\28xlarge\29
{
    margin-left: 8.3333333333%;
  }
}

@media screen and (max-width: 1280px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row {
    margin: 0 0 -1px -1.5em;
  }
  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }
  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }
  .row.\32 00\25 > * {
    padding: 0 0 0 3em;
  }
  .row.\32 00\25
{
    margin: 0 0 -1px -3em;
  }
  .row.uniform.\32 00\25 > * {
    padding: 3em 0 0 3em;
  }
  .row.uniform.\32 00\25
{
    margin: -3em 0 -1px -3em;
  }
  .row.\31 50\25 > * {
    padding: 0 0 0 2.25em;
  }
  .row.\31 50\25
{
    margin: 0 0 -1px -2.25em;
  }
  .row.uniform.\31 50\25 > * {
    padding: 2.25em 0 0 2.25em;
  }
  .row.uniform.\31 50\25
{
    margin: -2.25em 0 -1px -2.25em;
  }
  .row.\35 0\25 > * {
    padding: 0 0 0 0.75em;
  }
  .row.\35 0\25
{
    margin: 0 0 -1px -0.75em;
  }
  .row.uniform.\35 0\25 > * {
    padding: 0.75em 0 0 0.75em;
  }
  .row.uniform.\35 0\25
{
    margin: -0.75em 0 -1px -0.75em;
  }
  .row.\32 5\25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.\32 5\25
{
    margin: 0 0 -1px -0.375em;
  }
  .row.uniform.\32 5\25 > * {
    padding: 0.375em 0 0 0.375em;
  }
  .row.uniform.\32 5\25
{
    margin: -0.375em 0 -1px -0.375em;
  }
  .\31 2u\28large\29, .\31 2u\24\28large\29
{
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28large\29, .\31 1u\24\28large\29
{
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28large\29, .\31 0u\24\28large\29
{
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28large\29, .\39 u\24\28large\29
{
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28large\29, .\38 u\24\28large\29
{
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28large\29, .\37 u\24\28large\29
{
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28large\29, .\36 u\24\28large\29
{
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28large\29, .\35 u\24\28large\29
{
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28large\29, .\34 u\24\28large\29
{
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28large\29, .\33 u\24\28large\29
{
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28large\29, .\32 u\24\28large\29
{
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28large\29, .\31 u\24\28large\29
{
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28large\29 + *,
  .\31 1u\24\28large\29 + *,
  .\31 0u\24\28large\29 + *,
  .\39 u\24\28large\29 + *,
  .\38 u\24\28large\29 + *,
  .\37 u\24\28large\29 + *,
  .\36 u\24\28large\29 + *,
  .\35 u\24\28large\29 + *,
  .\34 u\24\28large\29 + *,
  .\33 u\24\28large\29 + *,
  .\32 u\24\28large\29 + *,
  .\31 u\24\28large\29 + * {
    clear: left;
  }
  .\-11u\28large\29
{
    margin-left: 91.6666666667%;
  }
  .\-10u\28large\29
{
    margin-left: 83.3333333333%;
  }
  .\-9u\28large\29
{
    margin-left: 75%;
  }
  .\-8u\28large\29
{
    margin-left: 66.6666666667%;
  }
  .\-7u\28large\29
{
    margin-left: 58.3333333333%;
  }
  .\-6u\28large\29
{
    margin-left: 50%;
  }
  .\-5u\28large\29
{
    margin-left: 41.6666666667%;
  }
  .\-4u\28large\29
{
    margin-left: 33.3333333333%;
  }
  .\-3u\28large\29
{
    margin-left: 25%;
  }
  .\-2u\28large\29
{
    margin-left: 16.6666666667%;
  }
  .\-1u\28large\29
{
    margin-left: 8.3333333333%;
  }
}

@media screen and (max-width: 980px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row {
    margin: 0 0 -1px -1.5em;
  }
  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }
  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }
  .row.\32 00\25 > * {
    padding: 0 0 0 3em;
  }
  .row.\32 00\25
{
    margin: 0 0 -1px -3em;
  }
  .row.uniform.\32 00\25 > * {
    padding: 3em 0 0 3em;
  }
  .row.uniform.\32 00\25
{
    margin: -3em 0 -1px -3em;
  }
  .row.\31 50\25 > * {
    padding: 0 0 0 2.25em;
  }
  .row.\31 50\25
{
    margin: 0 0 -1px -2.25em;
  }
  .row.uniform.\31 50\25 > * {
    padding: 2.25em 0 0 2.25em;
  }
  .row.uniform.\31 50\25
{
    margin: -2.25em 0 -1px -2.25em;
  }
  .row.\35 0\25 > * {
    padding: 0 0 0 0.75em;
  }
  .row.\35 0\25
{
    margin: 0 0 -1px -0.75em;
  }
  .row.uniform.\35 0\25 > * {
    padding: 0.75em 0 0 0.75em;
  }
  .row.uniform.\35 0\25
{
    margin: -0.75em 0 -1px -0.75em;
  }
  .row.\32 5\25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.\32 5\25
{
    margin: 0 0 -1px -0.375em;
  }
  .row.uniform.\32 5\25 > * {
    padding: 0.375em 0 0 0.375em;
  }
  .row.uniform.\32 5\25
{
    margin: -0.375em 0 -1px -0.375em;
  }
  .\31 2u\28medium\29, .\31 2u\24\28medium\29
{
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28medium\29, .\31 1u\24\28medium\29
{
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28medium\29, .\31 0u\24\28medium\29
{
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28medium\29, .\39 u\24\28medium\29
{
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28medium\29, .\38 u\24\28medium\29
{
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28medium\29, .\37 u\24\28medium\29
{
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28medium\29, .\36 u\24\28medium\29
{
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28medium\29, .\35 u\24\28medium\29
{
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28medium\29, .\34 u\24\28medium\29
{
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28medium\29, .\33 u\24\28medium\29
{
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28medium\29, .\32 u\24\28medium\29
{
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28medium\29, .\31 u\24\28medium\29
{
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28medium\29 + *,
  .\31 1u\24\28medium\29 + *,
  .\31 0u\24\28medium\29 + *,
  .\39 u\24\28medium\29 + *,
  .\38 u\24\28medium\29 + *,
  .\37 u\24\28medium\29 + *,
  .\36 u\24\28medium\29 + *,
  .\35 u\24\28medium\29 + *,
  .\34 u\24\28medium\29 + *,
  .\33 u\24\28medium\29 + *,
  .\32 u\24\28medium\29 + *,
  .\31 u\24\28medium\29 + * {
    clear: left;
  }
  .\-11u\28medium\29
{
    margin-left: 91.6666666667%;
  }
  .\-10u\28medium\29
{
    margin-left: 83.3333333333%;
  }
  .\-9u\28medium\29
{
    margin-left: 75%;
  }
  .\-8u\28medium\29
{
    margin-left: 66.6666666667%;
  }
  .\-7u\28medium\29
{
    margin-left: 58.3333333333%;
  }
  .\-6u\28medium\29
{
    margin-left: 50%;
  }
  .\-5u\28medium\29
{
    margin-left: 41.6666666667%;
  }
  .\-4u\28medium\29
{
    margin-left: 33.3333333333%;
  }
  .\-3u\28medium\29
{
    margin-left: 25%;
  }
  .\-2u\28medium\29
{
    margin-left: 16.6666666667%;
  }
  .\-1u\28medium\29
{
    margin-left: 8.3333333333%;
  }
}

@media screen and (max-width: 736px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row {
    margin: 0 0 -1px -1.5em;
  }
  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }
  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }
  .row.\32 00\25 > * {
    padding: 0 0 0 3em;
  }
  .row.\32 00\25
{
    margin: 0 0 -1px -3em;
  }
  .row.uniform.\32 00\25 > * {
    padding: 3em 0 0 3em;
  }
  .row.uniform.\32 00\25
{
    margin: -3em 0 -1px -3em;
  }
  .row.\31 50\25 > * {
    padding: 0 0 0 2.25em;
  }
  .row.\31 50\25
{
    margin: 0 0 -1px -2.25em;
  }
  .row.uniform.\31 50\25 > * {
    padding: 2.25em 0 0 2.25em;
  }
  .row.uniform.\31 50\25
{
    margin: -2.25em 0 -1px -2.25em;
  }
  .row.\35 0\25 > * {
    padding: 0 0 0 0.75em;
  }
  .row.\35 0\25
{
    margin: 0 0 -1px -0.75em;
  }
  .row.uniform.\35 0\25 > * {
    padding: 0.75em 0 0 0.75em;
  }
  .row.uniform.\35 0\25
{
    margin: -0.75em 0 -1px -0.75em;
  }
  .row.\32 5\25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.\32 5\25
{
    margin: 0 0 -1px -0.375em;
  }
  .row.uniform.\32 5\25 > * {
    padding: 0.375em 0 0 0.375em;
  }
  .row.uniform.\32 5\25
{
    margin: -0.375em 0 -1px -0.375em;
  }
  .\31 2u\28small\29, .\31 2u\24\28small\29
{
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28small\29, .\31 1u\24\28small\29
{
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28small\29, .\31 0u\24\28small\29
{
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28small\29, .\39 u\24\28small\29
{
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28small\29, .\38 u\24\28small\29
{
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28small\29, .\37 u\24\28small\29
{
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28small\29, .\36 u\24\28small\29
{
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28small\29, .\35 u\24\28small\29
{
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28small\29, .\34 u\24\28small\29
{
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28small\29, .\33 u\24\28small\29
{
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28small\29, .\32 u\24\28small\29
{
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28small\29, .\31 u\24\28small\29
{
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28small\29 + *,
  .\31 1u\24\28small\29 + *,
  .\31 0u\24\28small\29 + *,
  .\39 u\24\28small\29 + *,
  .\38 u\24\28small\29 + *,
  .\37 u\24\28small\29 + *,
  .\36 u\24\28small\29 + *,
  .\35 u\24\28small\29 + *,
  .\34 u\24\28small\29 + *,
  .\33 u\24\28small\29 + *,
  .\32 u\24\28small\29 + *,
  .\31 u\24\28small\29 + * {
    clear: left;
  }
  .\-11u\28small\29
{
    margin-left: 91.6666666667%;
  }
  .\-10u\28small\29
{
    margin-left: 83.3333333333%;
  }
  .\-9u\28small\29
{
    margin-left: 75%;
  }
  .\-8u\28small\29
{
    margin-left: 66.6666666667%;
  }
  .\-7u\28small\29
{
    margin-left: 58.3333333333%;
  }
  .\-6u\28small\29
{
    margin-left: 50%;
  }
  .\-5u\28small\29
{
    margin-left: 41.6666666667%;
  }
  .\-4u\28small\29
{
    margin-left: 33.3333333333%;
  }
  .\-3u\28small\29
{
    margin-left: 25%;
  }
  .\-2u\28small\29
{
    margin-left: 16.6666666667%;
  }
  .\-1u\28small\29
{
    margin-left: 8.3333333333%;
  }
}

@media screen and (max-width: 480px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row {
    margin: 0 0 -1px -1.5em;
  }
  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }
  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }
  .row.\32 00\25 > * {
    padding: 0 0 0 3em;
  }
  .row.\32 00\25
{
    margin: 0 0 -1px -3em;
  }
  .row.uniform.\32 00\25 > * {
    padding: 3em 0 0 3em;
  }
  .row.uniform.\32 00\25
{
    margin: -3em 0 -1px -3em;
  }
  .row.\31 50\25 > * {
    padding: 0 0 0 2.25em;
  }
  .row.\31 50\25
{
    margin: 0 0 -1px -2.25em;
  }
  .row.uniform.\31 50\25 > * {
    padding: 2.25em 0 0 2.25em;
  }
  .row.uniform.\31 50\25
{
    margin: -2.25em 0 -1px -2.25em;
  }
  .row.\35 0\25 > * {
    padding: 0 0 0 0.75em;
  }
  .row.\35 0\25
{
    margin: 0 0 -1px -0.75em;
  }
  .row.uniform.\35 0\25 > * {
    padding: 0.75em 0 0 0.75em;
  }
  .row.uniform.\35 0\25
{
    margin: -0.75em 0 -1px -0.75em;
  }
  .row.\32 5\25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.\32 5\25
{
    margin: 0 0 -1px -0.375em;
  }
  .row.uniform.\32 5\25 > * {
    padding: 0.375em 0 0 0.375em;
  }
  .row.uniform.\32 5\25
{
    margin: -0.375em 0 -1px -0.375em;
  }
  .\31 2u\28xsmall\29, .\31 2u\24\28xsmall\29
{
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28xsmall\29, .\31 1u\24\28xsmall\29
{
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28xsmall\29, .\31 0u\24\28xsmall\29
{
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28xsmall\29, .\39 u\24\28xsmall\29
{
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28xsmall\29, .\38 u\24\28xsmall\29
{
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28xsmall\29, .\37 u\24\28xsmall\29
{
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28xsmall\29, .\36 u\24\28xsmall\29
{
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28xsmall\29, .\35 u\24\28xsmall\29
{
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28xsmall\29, .\34 u\24\28xsmall\29
{
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28xsmall\29, .\33 u\24\28xsmall\29
{
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28xsmall\29, .\32 u\24\28xsmall\29
{
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28xsmall\29, .\31 u\24\28xsmall\29
{
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28xsmall\29 + *,
  .\31 1u\24\28xsmall\29 + *,
  .\31 0u\24\28xsmall\29 + *,
  .\39 u\24\28xsmall\29 + *,
  .\38 u\24\28xsmall\29 + *,
  .\37 u\24\28xsmall\29 + *,
  .\36 u\24\28xsmall\29 + *,
  .\35 u\24\28xsmall\29 + *,
  .\34 u\24\28xsmall\29 + *,
  .\33 u\24\28xsmall\29 + *,
  .\32 u\24\28xsmall\29 + *,
  .\31 u\24\28xsmall\29 + * {
    clear: left;
  }
  .\-11u\28xsmall\29
{
    margin-left: 91.6666666667%;
  }
  .\-10u\28xsmall\29
{
    margin-left: 83.3333333333%;
  }
  .\-9u\28xsmall\29
{
    margin-left: 75%;
  }
  .\-8u\28xsmall\29
{
    margin-left: 66.6666666667%;
  }
  .\-7u\28xsmall\29
{
    margin-left: 58.3333333333%;
  }
  .\-6u\28xsmall\29
{
    margin-left: 50%;
  }
  .\-5u\28xsmall\29
{
    margin-left: 41.6666666667%;
  }
  .\-4u\28xsmall\29
{
    margin-left: 33.3333333333%;
  }
  .\-3u\28xsmall\29
{
    margin-left: 25%;
  }
  .\-2u\28xsmall\29
{
    margin-left: 16.6666666667%;
  }
  .\-1u\28xsmall\29
{
    margin-left: 8.3333333333%;
  }
}

/* Basic */
@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
  html, body {
    min-width: 320px;
  }
}

body {
  background: #fff;
}

body.is-loading *, body.is-loading *:before, body.is-loading *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

/* Type */
html {
  font-size: 14pt;
}

@media screen and (max-width: 1680px) {
  html {
    font-size: 11pt;
  }
}

@media screen and (max-width: 980px) {
  html {
    font-size: 12pt;
  }
}

@media screen and (max-width: 736px) {
  html {
    font-size: 11pt;
  }
}

body {
  background-color: #fff;
  color: #888;
}

body, input, select, textarea {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2;
}

a {
  -moz-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  -ms-transition: color 0.2s;
  transition: color 0.2s;
  text-decoration: underline;
}

a:active, a:focus, a:hover {
  text-decoration: none;
}

strong, b {
  font-weight: 700;
}

em, i {
  font-style: italic;
}

p {
  margin: 0 0 2.5rem 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  line-height: 1.4;
  margin: 0 0 1.5rem 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 4rem;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 1280px) {
  h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 980px) {
  h1 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 736px) {
  h1 {
    font-size: 2.25rem;
  }
}

h2 {
  font-size: 3.5rem;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 1280px) {
  h2 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 980px) {
  h2 {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 736px) {
  h2 {
    font-size: 1.75rem;
  }
}

h3 {
  font-size: 2.15rem;
}

@media screen and (max-width: 980px) {
  h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 736px) {
  h3 {
    font-size: 1.8rem;
  }
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}

.metas {
  font-size: 12px;
  color: #ccc;
  margin-top: -10px;
  margin-bottom: 10px;
}

.inner ul.pagination {
  text-align: center;
  list-style: none;
  font-size: 18px;
}

.inner ul.pagination li {
  display: inline;
  background-image: none;
}

.inner ul.pagination li a {
  text-decoration: none;
}

sub {
  font-size: 0.8rem;
  position: relative;
  top: 0.5rem;
}

sup {
  font-size: 0.8rem;
  position: relative;
  top: -0.5rem;
}

blockquote {
  border-left: solid 12px;
  font-style: italic;
  margin: 0 0 2.5rem 0;
  padding: 0.625rem 0 0.625rem 2.5rem;
}

blockquote p {
  margin-bottom: 0;
}

.snappy-quote {
  font-style: italic;
  font-size: 1.5rem;
  margin: 0 0 2.5rem 0;
  padding: 0.625rem 0 0.625rem 0;
}

.snappy-quote p {
  margin-bottom: 0;
}

.snappy-quote:after {
  width: 50px;
  border-bottom: 4px solid #FD81B1;
  content: '';
  display: block;
}

code {
  border-radius: 4px;
  border: solid 1px;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  margin: 0 0.25rem;
  padding: 0.25rem 0.65rem;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  margin: 0 0 2.5rem 0;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1rem 1.5rem;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px;
  margin: 2.5rem 0;
}

hr.major {
  margin: 3.75rem 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

input, select, textarea {
  color: #666666;
}

a {
  color: #FD81B1;
}

strong, b {
  color: #666666;
}

h1, h2, h3, h4, h5, h6 {
  color: #666666;
}

blockquote {
  border-left-color: rgba(144, 144, 144, 0.25);
}

code {
  background: rgba(144, 144, 144, 0.075);
  border-color: rgba(144, 144, 144, 0.25);
}

hr {
  border-bottom-color: rgba(144, 144, 144, 0.25);
}

/*!
 * Font Awesome Pro 5.0.6 by @fontawesome - http://fontawesome.com
 * License - http://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: Font Awesome\ 5 Pro;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/fa-regular-400.eot);
  src: url(./fonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(./fonts/fa-regular-400.woff2) format("woff2"), url(./fonts/fa-regular-400.woff) format("woff"), url(./fonts/fa-regular-400.ttf) format("truetype"), url(./fonts/fa-regular-400.svg#fontawesome) format("svg");
}

.far {
  font-family: Font Awesome\ 5 Pro;
  font-weight: 400;
}

/* Box */
.box {
  border: solid 1px;
  margin-bottom: 2.5rem;
  padding: 1.5rem;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box.alt {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.box {
  border-color: rgba(144, 144, 144, 0.25);
}

/* Button */
input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: 0.85rem;
  height: 3rem;
  letter-spacing: 0.04rem;
  line-height: 3rem;
  padding: 0 2.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
  margin-right: 0.5rem;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  display: block;
  margin: 0 0 1.25rem 0;
  width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
  font-size: 0.7rem;
  height: 2.2rem;
  line-height: 2.2rem;
}

input[type="submit"].big,
input[type="reset"].big,
input[type="button"].big,
button.big,
.button.big {
  font-size: 1.1rem;
  height: 4.25rem;
  line-height: 4.25rem;
}

input[type="submit"].wide,
input[type="reset"].wide,
input[type="button"].wide,
button.wide,
.button.wide {
  min-width: 15em;
}

input[type="submit"].disabled, input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  -moz-pointer-events: none;
  -webkit-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  opacity: 0.25;
}

input[type="submit"].special.accent1,
input[type="reset"].special.accent1,
input[type="button"].special.accent1,
button.special.accent1,
.button.special.accent1 {
  background-color: #FD81B1 !important;
  color: #ffffff !important;
}

input[type="submit"].special.accent1:hover, input[type="submit"].special.accent1:focus,
input[type="reset"].special.accent1:hover,
input[type="reset"].special.accent1:focus,
input[type="button"].special.accent1:hover,
input[type="button"].special.accent1:focus,
button.special.accent1:hover,
button.special.accent1:focus,
.button.special.accent1:hover,
.button.special.accent1:focus {
  background-color: #fd9ac1 !important;
}

input[type="submit"].special.accent1:active,
input[type="reset"].special.accent1:active,
input[type="button"].special.accent1:active,
button.special.accent1:active,
.button.special.accent1:active {
  background-color: #fd68a1 !important;
}

input[type="submit"].special.accent2,
input[type="reset"].special.accent2,
input[type="button"].special.accent2,
button.special.accent2,
.button.special.accent2 {
  background-color: #00BEE6 !important;
  color: #ffffff !important;
}

input[type="submit"].special.accent2:hover, input[type="submit"].special.accent2:focus,
input[type="reset"].special.accent2:hover,
input[type="reset"].special.accent2:focus,
input[type="button"].special.accent2:hover,
input[type="button"].special.accent2:focus,
button.special.accent2:hover,
button.special.accent2:focus,
.button.special.accent2:hover,
.button.special.accent2:focus {
  background-color: #01d3ff !important;
}

input[type="submit"].special.accent2:active,
input[type="reset"].special.accent2:active,
input[type="button"].special.accent2:active,
button.special.accent2:active,
.button.special.accent2:active {
  background-color: #00a9cd !important;
}

input[type="submit"].special.accent3,
input[type="reset"].special.accent3,
input[type="button"].special.accent3,
button.special.accent3,
.button.special.accent3 {
  background-color: #594095 !important;
  color: #ffffff !important;
}

input[type="submit"].special.accent3:hover, input[type="submit"].special.accent3:focus,
input[type="reset"].special.accent3:hover,
input[type="reset"].special.accent3:focus,
input[type="button"].special.accent3:hover,
input[type="button"].special.accent3:focus,
button.special.accent3:hover,
button.special.accent3:focus,
.button.special.accent3:hover,
.button.special.accent3:focus {
  background-color: #6448a7 !important;
}

input[type="submit"].special.accent3:active,
input[type="reset"].special.accent3:active,
input[type="button"].special.accent3:active,
button.special.accent3:active,
.button.special.accent3:active {
  background-color: #4e3883 !important;
}

input[type="submit"].special.accent4,
input[type="reset"].special.accent4,
input[type="button"].special.accent4,
button.special.accent4,
.button.special.accent4 {
  background-color: #6E81D0 !important;
  color: #ffffff !important;
}

input[type="submit"].special.accent4:hover, input[type="submit"].special.accent4:focus,
input[type="reset"].special.accent4:hover,
input[type="reset"].special.accent4:focus,
input[type="button"].special.accent4:hover,
input[type="button"].special.accent4:focus,
button.special.accent4:hover,
button.special.accent4:focus,
.button.special.accent4:hover,
.button.special.accent4:focus {
  background-color: #8192d6 !important;
}

input[type="submit"].special.accent4:active,
input[type="reset"].special.accent4:active,
input[type="button"].special.accent4:active,
button.special.accent4:active,
.button.special.accent4:active {
  background-color: #5b70ca !important;
}

@media screen and (max-width: 480px) {
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button,
  .button {
    padding: 0;
  }
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  background-color: transparent;
  box-shadow: inset 0 0 0 3px rgba(144, 144, 144, 0.25);
  color: #666666;
}

input[type="submit"]:hover, input[type="submit"]:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
input[type="button"]:hover,
input[type="button"]:focus,
button:hover,
button:focus,
.button:hover,
.button:focus {
  background-color: rgba(144, 144, 144, 0.075);
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active {
  background-color: rgba(144, 144, 144, 0.175);
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
  color: #bbb;
}

input[type="submit"].special,
input[type="reset"].special,
input[type="button"].special,
button.special,
.button.special {
  box-shadow: none;
  background-color: #FD81B1;
  color: #ffffff;
}

input[type="submit"].special.icon:before,
input[type="reset"].special.icon:before,
input[type="button"].special.icon:before,
button.special.icon:before,
.button.special.icon:before {
  color: #ffffff;
}

input[type="submit"].special:hover, input[type="submit"].special:focus,
input[type="reset"].special:hover,
input[type="reset"].special:focus,
input[type="button"].special:hover,
input[type="button"].special:focus,
button.special:hover,
button.special:focus,
.button.special:hover,
.button.special:focus {
  background-color: #fd9ac1;
}

input[type="submit"].special:active,
input[type="reset"].special:active,
input[type="button"].special:active,
button.special:active,
.button.special:active {
  background-color: #fd68a1;
}

/* FAQ */
.faq-box {
  margin-bottom: 4rem;
}

.faq-box h4 {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 30px;
  margin-bottom: 0;
}

.faq-box h4:after {
  border-bottom: 5px solid #FD81B1;
  width: 40px;
  content: '';
  display: block;
  padding-top: 20px;
}

.faq-box .answer {
  padding: 30px;
}

.faq-wrap {
  border-bottom: 1px solid #f1f1f1;
}

.question {
  font-size: 1.2rem;
  cursor: pointer;
  padding: 10px 10px;
}

.question:after {
  content: "\f107";
  font-family: 'Font Awesome\ 5 Pro';
  font-weight: normal;
  margin-right: 10px;
  speak: none;
  float: right;
  color: #FD81B1;
}

.question.close:after {
  content: "\f106";
}

/* Form */
.contact-parent {
  display: flex;
}

.contact-parent .contact-details {
  flex-basis: 50%;
  background: #fd9ac1;
  padding: 30px;
  color: #fff;
}

.contact-parent .contact-details h3 {
  color: #fff;
}

.contact-parent .contact-details a {
  color: #fff;
}

.contact-parent .contact-form {
  flex-basis: 50%;
  padding: 30px;
  background: #f2f2f2;
  position: relative;
}

.contact-parent .contact-form:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-top: 30px solid #fff;
  border-left: 30px solid #f2f2f2;
  width: 0;
}

form {
  margin: 0 0 2.5rem 0;
}

header.major + form {
  margin-top: -1.5rem;
}

form.combined {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative;
  width: 30rem;
}

form.combined input[type="text"],
form.combined input[type="password"],
form.combined input[type="email"],
form.combined select,
form.combined textarea {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

form.combined input[type="submit"] {
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media screen and (max-width: 480px) {
  form.combined {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  form.combined input[type="text"],
  form.combined input[type="password"],
  form.combined input[type="email"],
  form.combined select,
  form.combined textarea {
    border-radius: 4px;
    margin: 0 0 1em 0;
  }
  form.combined input[type="submit"] {
    border-radius: 4px;
  }
}

label {
  display: block;
  font-size: 0.9rem;
  font-weight: 700;
  margin: 0 0 1.25rem 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: solid 3px;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1rem;
  text-decoration: none;
  width: 100%;
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

.select-wrapper {
  text-decoration: none;
  display: block;
  position: relative;
}

.select-wrapper:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Pro;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.select-wrapper:before {
  content: '\f078';
  display: block;
  height: 3rem;
  line-height: 3rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 3rem;
}

.select-wrapper select::-ms-expand {
  display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: 3rem;
}

textarea {
  padding: 0.75rem 1rem;
}

input[type="text"].accent1:focus,
input[type="password"].accent1:focus,
input[type="email"].accent1:focus,
select.accent1:focus,
textarea.accent1:focus {
  border-color: #FD81B1 !important;
}

input[type="text"].accent2:focus,
input[type="password"].accent2:focus,
input[type="email"].accent2:focus,
select.accent2:focus,
textarea.accent2:focus {
  border-color: #00BEE6 !important;
}

input[type="text"].accent3:focus,
input[type="password"].accent3:focus,
input[type="email"].accent3:focus,
select.accent3:focus,
textarea.accent3:focus {
  border-color: #594095 !important;
}

input[type="text"].accent4:focus,
input[type="password"].accent4:focus,
input[type="email"].accent4:focus,
select.accent4:focus,
textarea.accent4:focus {
  border-color: #6E81D0 !important;
}

input[type="checkbox"],
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2rem;
  opacity: 0;
  width: 1rem;
  z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 2.55rem;
  padding-right: 0.75rem;
  position: relative;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Pro;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  border-radius: 4px;
  border: solid 3px;
  content: '';
  display: inline-block;
  height: 1.8rem;
  left: 0;
  line-height: 1.725rem;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1.8rem;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  content: '\f00c';
}

input[type="checkbox"] + label:before {
  border-radius: 4px;
}

input[type="radio"] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  opacity: 1.0;
}

:-moz-placeholder {
  opacity: 1.0;
}

::-moz-placeholder {
  opacity: 1.0;
}

:-ms-input-placeholder {
  opacity: 1.0;
}

label {
  color: #666666;
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  background: #fff;
  border-color: rgba(144, 144, 144, 0.25);
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
  border-color: #FD81B1;
}

input[type="text"].alt,
input[type="password"].alt,
input[type="email"].alt,
select.alt,
textarea.alt {
  background-color: #666666;
  border-color: #666666;
  color: #fff;
}

input[type="text"].alt:focus,
input[type="password"].alt:focus,
input[type="email"].alt:focus,
select.alt:focus,
textarea.alt:focus {
  border-color: #bbb;
}

.select-wrapper:before {
  color: rgba(144, 144, 144, 0.25);
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  color: #888;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  background: rgba(144, 144, 144, 0.075);
  border-color: rgba(144, 144, 144, 0.25);
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  background-color: #FD81B1;
  border-color: #FD81B1;
  color: #ffffff;
}

input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
  border-color: #FD81B1;
}

::-webkit-input-placeholder {
  color: #bbb !important;
}

.alt::-webkit-input-placeholder {
  color: #bbb !important;
}

:-moz-placeholder {
  color: #bbb !important;
}

.alt:-moz-placeholder {
  color: #bbb !important;
}

::-moz-placeholder {
  color: #bbb !important;
}

.alt::-moz-placeholder {
  color: #bbb !important;
}

:-ms-input-placeholder {
  color: #bbb !important;
}

.alt:-ms-input-placeholder {
  color: #bbb !important;
}

/* Icon */
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Pro;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.icon > .label {
  display: none;
}

/* Image */
.image {
  border: 0;
  display: inline-block;
  position: relative;
}

.image[data-position] img {
  -moz-object-fit: cover;
  -webkit-object-fit: cover;
  -ms-object-fit: cover;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image[data-position="top left"] img {
  -moz-object-position: top left;
  -webkit-object-position: top left;
  -ms-object-position: top left;
  object-position: top left;
}

.image[data-position="top"] img {
  -moz-object-position: top;
  -webkit-object-position: top;
  -ms-object-position: top;
  object-position: top;
}

.image[data-position="top right"] img {
  -moz-object-position: top right;
  -webkit-object-position: top right;
  -ms-object-position: top right;
  object-position: top right;
}

.image[data-position="right"] img {
  -moz-object-position: right;
  -webkit-object-position: right;
  -ms-object-position: right;
  object-position: right;
}

.image[data-position="bottom right"] img {
  -moz-object-position: bottom right;
  -webkit-object-position: bottom right;
  -ms-object-position: bottom right;
  object-position: bottom right;
}

.image[data-position="bottom"] img {
  -moz-object-position: bottom;
  -webkit-object-position: bottom;
  -ms-object-position: bottom;
  object-position: bottom;
}

.image[data-position="bottom left"] img {
  -moz-object-position: bottom left;
  -webkit-object-position: bottom left;
  -ms-object-position: bottom left;
  object-position: bottom left;
}

.image[data-position="left"] img {
  -moz-object-position: left;
  -webkit-object-position: left;
  -ms-object-position: left;
  object-position: left;
}

.image[data-position="center"] img {
  -moz-object-position: center;
  -webkit-object-position: center;
  -ms-object-position: center;
  object-position: center;
}

.image[data-position="25% 25%"] img {
  -moz-object-position: 25% 25%;
  -webkit-object-position: 25% 25%;
  -ms-object-position: 25% 25%;
  object-position: 25% 25%;
}

.image[data-position="75% 25%"] img {
  -moz-object-position: 75% 25%;
  -webkit-object-position: 75% 25%;
  -ms-object-position: 75% 25%;
  object-position: 75% 25%;
}

.image[data-position="75% 75%"] img {
  -moz-object-position: 75% 75%;
  -webkit-object-position: 75% 75%;
  -ms-object-position: 75% 75%;
  object-position: 75% 75%;
}

.image[data-position="25% 75%"] img {
  -moz-object-position: 25% 75%;
  -webkit-object-position: 25% 75%;
  -ms-object-position: 25% 75%;
  object-position: 25% 75%;
}

.image img {
  display: block;
  max-width: 100%;
}

.image.left, .image.right {
  max-width: 40%;
}

.image.left img, .image.right img {
  width: 100%;
}

.image.left {
  float: left;
  margin: 0 1.5rem 1rem 0;
  top: 0.25rem;
}

.image.right {
  float: right;
  margin: 0 0 1rem 1.5rem;
  top: 0.25rem;
}

.image.fit {
  display: block;
  margin: 0 0 2.5rem 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
}

.image.main {
  display: block;
  margin: 0 0 3.75rem 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

@media screen and (max-width: 736px) {
  .image.main {
    margin: 0 0 2.5rem 0;
  }
}

a.image {
  overflow: hidden;
}

a.image img {
  -moz-transition: -moz-transform 0.2s ease;
  -webkit-transition: -webkit-transform 0.2s ease;
  -ms-transition: -ms-transform 0.2s ease;
  transition: transform 0.2s ease;
}

a.image:hover img {
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/* List */
.inner ul {
  list-style: none;
  margin: 0 0 2.5rem 0;
  padding-left: 1rem;
}

.inner ul li {
  padding-left: 1.1rem;
  background-image: url(../img/cog.svg);
  background-position: left 8px;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

ol {
  list-style: decimal;
  margin: 0 0 2.5rem 0;
  padding-left: 1.25rem;
}

ol li {
  padding-left: 0.25rem;
}

ul {
  list-style: disc;
  margin: 0 0 2.5rem 0;
  padding-left: 1rem;
}

ul li {
  padding-left: 0.5rem;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px;
  padding: 0.5rem 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.icons li {
  display: inline-block;
  padding: 0 1rem 0 0;
}

ul.icons li:last-child {
  padding-right: 0;
}

ul.icons li .icon:before {
  font-size: 2rem;
}

ul.actions {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.actions li {
  display: inline-block;
  padding: 0 1.25rem 0 0;
  vertical-align: middle;
  background: none;
}

ul.actions li:last-child {
  padding-right: 0;
}

ul.actions.small li {
  padding: 0 0.625rem 0 0;
}

ul.actions.vertical li {
  display: block;
  padding: 1.25rem 0 0 0;
}

ul.actions.vertical li:first-child {
  padding-top: 0;
}

ul.actions.vertical li > * {
  margin-bottom: 0;
}

ul.actions.vertical.small li {
  padding: 0.625rem 0 0 0;
}

ul.actions.vertical.small li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  display: table;
  margin-left: -1.25rem;
  padding: 0;
  table-layout: fixed;
  width: calc(100% + 1.25rem);
}

ul.actions.fit li {
  display: table-cell;
  padding: 0 0 0 1.25rem;
}

ul.actions.fit li > * {
  margin-bottom: 0;
}

ul.actions.fit.small {
  margin-left: -0.625rem;
  width: calc(100% + 0.625rem);
}

ul.actions.fit.small li {
  padding: 0 0 0 0.625rem;
}

@media screen and (max-width: 480px) {
  ul.actions {
    margin: 0 0 2.5rem 0;
  }
  ul.actions li {
    padding: 1.25rem 0 0 0;
    display: block;
    text-align: center;
    width: 100%;
  }
  ul.actions li:first-child {
    padding-top: 0;
  }
  ul.actions li > * {
    width: 100%;
    margin: 0 !important;
  }
  ul.actions li > *.icon:before {
    margin-left: -2rem;
  }
  ul.actions.small li {
    padding: 0.625rem 0 0 0;
  }
  ul.actions.small li:first-child {
    padding-top: 0;
  }
}

ul.contact-icons {
  list-style: none;
  padding-left: 0;
}

ul.contact-icons li:before {
  padding-left: 0;
  margin-right: 1em;
}

dl {
  margin: 0 0 2.5rem 0;
}

dl dt {
  display: block;
  font-weight: 700;
  margin: 0 0 1.25rem 0;
}

dl dd {
  margin-left: 2.5rem;
}

ul.alt li {
  border-top-color: rgba(144, 144, 144, 0.25);
}

.side-by-side {
  display: flex;
  justify-content: space-between;
}

.side-by-side .leftside {
  flex-basis: 48%;
}

.side-by-side .right-side {
  flex-basis: 48%;
}

@media screen and (max-width: 980px) {
  .side-by-side {
    display: block;
  }
}

.available-dates {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.available-dates .date-child {
  flex-basis: 30%;
  background: #f2f2f2;
  padding: 40px 60px;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.available-dates .date-child::before {
  content: '\f073';
  font-family: Font Awesome\ 5 Pro;
  position: absolute;
  top: 0;
  left: -15px;
  font-size: 60px;
  color: #ddd;
}

@media screen and (max-width: 980px) {
  .available-dates .date-child {
    flex-basis: 48%;
  }
}

@media screen and (max-width: 480px) {
  .available-dates {
    display: block;
  }
}

.price-per-person {
  background: #FD81B1;
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.price-per-person::before {
  font-family: Font Awesome\ 5 Pro;
  position: absolute;
  top: -5px;
  left: -10px;
  font-size: 40px;
  color: #fff;
  content: '\f154';
}

.extra-prices {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.extra-prices .extra-prices-child {
  flex-basis: 48%;
  padding: 20px 40px;
  background: #00BEE6;
  text-align: center;
  color: #fff;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}

.extra-prices .supplement-fee::before,
.extra-prices .bike-hire::before,
.extra-prices .e-bike::before,
.extra-prices .maximum-people::before {
  font-family: Font Awesome\ 5 Pro;
  position: absolute;
  top: -5px;
  left: -10px;
  font-size: 40px;
  color: #fff;
}

.extra-prices .supplement-fee::before {
  content: '\f007';
}

.extra-prices .bike-hire::before {
  content: '\f206';
}

.extra-prices .e-bike::before {
  content: '\f21c';
}

.extra-prices .maximum-people::before {
  content: '\f0c0';
}

@media screen and (max-width: 480px) {
  .extra-prices {
    display: block;
  }
}

/* Section/Article */
section.special, article.special {
  text-align: center;
}

.major-hero {
  position: relative;
  margin-bottom: 5rem;
}

.major-hero:before {
  background-image: url("../img/top-corner.svg");
  width: 200px;
  display: block;
  height: 200px;
  content: '';
  right: -17px;
  position: absolute;
  top: -17px;
}

@media screen and (max-width: 980px) {
  .major-hero:before {
    width: 100px;
    height: 100px;
    top: -9px;
    right: -9px;
  }
}

@media screen and (max-width: 980px) {
  .major-hero:before {
    width: 50px;
    height: 50px;
    top: -4px;
    right: -4px;
  }
}

.major-hero h1 {
  position: absolute;
  bottom: -2rem;
  left: -2rem;
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  z-index: 1;
}

@media screen and (max-width: 980px) {
  .major-hero h1 {
    padding: 15px;
    bottom: -3rem;
  }
}

@media screen and (max-width: 736px) {
  .major-hero h1 {
    font-size: 26px;
    left: -1rem;
  }
}

.major-hero img {
  max-width: 100%;
  vertical-align: middle;
}

header p {
  max-width: 50rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

header h1 + p,
header h2 + p,
header h3 + p {
  margin-top: -1.375rem;
}

header h4 + p,
header h5 + p,
header h6 + p {
  font-size: 0.9rem;
  margin-top: -0.75rem;
}

header.major {
  margin-bottom: 4rem;
  text-align: center;
}

header.major h1:after, header.major h2:after {
  background-color: currentColor;
  content: '';
  display: block;
  height: 3px;
  margin: 1.5rem auto;
  width: 4rem;
}

header.major p {
  margin-top: 0;
}

@media screen and (max-width: 1280px) {
  header.major {
    margin-bottom: 3.5rem;
  }
}

@media screen and (max-width: 736px) {
  header.major {
    margin-bottom: 2rem;
  }
}

header p {
  color: #bbb;
}

/* Table */
.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2.5rem 0;
  width: 100%;
}

table tbody tr {
  border: solid 1px;
  border-left: 0;
  border-right: 0;
}

table td {
  padding: 0.75rem 0.75rem;
}

table th {
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0 0.75rem 0.75rem 0.75rem;
  text-align: left;
}

table thead {
  border-bottom: solid 3px;
}

table tfoot {
  border-top: solid 3px;
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px;
  border-left-width: 0;
  border-top-width: 0;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

table tbody tr {
  border-color: rgba(144, 144, 144, 0.25);
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(144, 144, 144, 0.075);
}

table th {
  color: #666666;
}

table thead {
  border-bottom-color: rgba(144, 144, 144, 0.25);
}

table tfoot {
  border-top-color: rgba(144, 144, 144, 0.25);
}

table.alt tbody tr td {
  border-color: rgba(144, 144, 144, 0.25);
}

/* Posts */
.posts {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -moz-align-items: stretch;
  -webkit-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  margin-bottom: 3.75rem;
}

.posts > * {
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
}

.posts > * {
  width: 50%;
}

.posts > * {
  padding: 1.5rem;
  width: calc(50% + 1.5rem);
}

.posts > :nth-child(-n + 2) {
  padding-top: 0;
}

.posts > :nth-last-child(-n + 2) {
  padding-bottom: 0;
}

.posts > :nth-child(2n + 1) {
  padding-left: 0;
}

.posts > :nth-child(2n) {
  padding-right: 0;
}

.posts > :nth-child(2n + 1),
.posts > :nth-child(2n) {
  width: calc(50% + 0rem);
}

.posts article {
  text-align: center;
}

.posts .image {
  display: block;
  width: 100%;
}

.posts .image img {
  width: 100%;
}

.posts .content {
  padding: 3rem 3rem 0.5rem 3rem ;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 980px) {
  .posts > * {
    width: 100%;
  }
  .posts > * {
    padding: 1.5rem;
    width: calc(50% + 1.5rem);
  }
  .posts > :nth-child(-n + 2) {
    padding-top: 1.5rem;
  }
  .posts > :nth-last-child(-n + 2) {
    padding-bottom: 1.5rem;
  }
  .posts > :nth-child(2n + 1) {
    padding-left: 1.5rem;
  }
  .posts > :nth-child(2n) {
    padding-right: 1.5rem;
  }
  .posts > :nth-child(2n + 1),
  .posts > :nth-child(2n) {
    padding: 1.5rem;
    width: calc(100% + 3rem);
  }
  .posts > * {
    padding: 1.5rem;
    width: calc(100% + 3rem);
  }
  .posts > :nth-child(-n + 1) {
    padding-top: 0;
  }
  .posts > :nth-last-child(-n + 1) {
    padding-bottom: 0;
  }
  .posts > :nth-child(1n + 1) {
    padding-left: 0;
  }
  .posts > :nth-child(1n) {
    padding-right: 0;
  }
  .posts > :nth-child(1n + 1),
  .posts > :nth-child(1n) {
    width: calc(100% + 1.5rem);
  }
  .posts .content {
    padding: 2.5rem 2rem 0.1rem 2rem ;
  }
}

@media screen and (max-width: 736px) {
  .posts > * {
    width: 100%;
  }
  .posts > * {
    padding: 1rem;
    width: calc(50% + 1rem);
  }
  .posts > :nth-child(-n + 2) {
    padding-top: 1rem;
  }
  .posts > :nth-last-child(-n + 2) {
    padding-bottom: 1rem;
  }
  .posts > :nth-child(2n + 1) {
    padding-left: 1rem;
  }
  .posts > :nth-child(2n) {
    padding-right: 1rem;
  }
  .posts > :nth-child(2n + 1),
  .posts > :nth-child(2n) {
    padding: 1rem;
    width: calc(100% + 2rem);
  }
  .posts > * {
    padding: 1rem;
    width: calc(100% + 2rem);
  }
  .posts > :nth-child(-n + 1) {
    padding-top: 0;
  }
  .posts > :nth-last-child(-n + 1) {
    padding-bottom: 0;
  }
  .posts > :nth-child(1n + 1) {
    padding-left: 0;
  }
  .posts > :nth-child(1n) {
    padding-right: 0;
  }
  .posts > :nth-child(1n + 1),
  .posts > :nth-child(1n) {
    width: calc(100% + 1rem);
  }
  .posts .content {
    padding: 2rem 1.5rem 0.1rem 1.5rem ;
  }
}

/* Team */
.team {
  display: flex;
  margin-bottom: 3.75rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.team article {
  text-align: center;
  flex-basis: 31%;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  margin-bottom: 3.75rem;
}

.team .image {
  display: block;
  max-width: 100%;
}

.team .image img {
  width: 100%;
}

.team .content {
  padding: 3rem 3rem 0.5rem 3rem ;
}

@media screen and (max-width: 980px) {
  .team article {
    flex-basis: 48%;
  }
  .team .content {
    padding: 2.5rem 2rem 0.1rem 2rem ;
  }
}

@media screen and (max-width: 480px) {
  .team article {
    flex-basis: 100%;
  }
  .team .content {
    padding: 2rem 1.5rem 0.1rem 1.5rem ;
  }
}

/* Wrapper */
.wrapper {
  padding: 6rem 0 3.5rem 0 ;
  position: relative;
  overflow-x: hidden;
}

.wrapper > .inner {
  margin: 0 auto;
  max-width: 75rem;
  width: calc(100% - 6rem);
}

.wrapper.style1 {
  background-color: #FD81B1;
  color: #ffe0ec;
}

.wrapper.style1 input, .wrapper.style1 select, .wrapper.style1 textarea {
  color: #ffffff;
}

.wrapper.style1 a {
  color: #ffffff;
}

.wrapper.style1 strong, .wrapper.style1 b {
  color: #ffffff;
}

.wrapper.style1 h1, .wrapper.style1 h2, .wrapper.style1 h3, .wrapper.style1 h4, .wrapper.style1 h5, .wrapper.style1 h6 {
  color: #ffffff;
}

.wrapper.style1 blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 .box {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 input[type="submit"],
.wrapper.style1 input[type="reset"],
.wrapper.style1 input[type="button"],
.wrapper.style1 button,
.wrapper.style1 .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.25);
  color: #ffffff;
}

.wrapper.style1 input[type="submit"]:hover, .wrapper.style1 input[type="submit"]:focus,
.wrapper.style1 input[type="reset"]:hover,
.wrapper.style1 input[type="reset"]:focus,
.wrapper.style1 input[type="button"]:hover,
.wrapper.style1 input[type="button"]:focus,
.wrapper.style1 button:hover,
.wrapper.style1 button:focus,
.wrapper.style1 .button:hover,
.wrapper.style1 .button:focus {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style1 input[type="submit"]:active,
.wrapper.style1 input[type="reset"]:active,
.wrapper.style1 input[type="button"]:active,
.wrapper.style1 button:active,
.wrapper.style1 .button:active {
  background-color: rgba(255, 255, 255, 0.175);
}

.wrapper.style1 input[type="submit"].icon:before,
.wrapper.style1 input[type="reset"].icon:before,
.wrapper.style1 input[type="button"].icon:before,
.wrapper.style1 button.icon:before,
.wrapper.style1 .button.icon:before {
  color: #fecde0;
}

.wrapper.style1 input[type="submit"].special,
.wrapper.style1 input[type="reset"].special,
.wrapper.style1 input[type="button"].special,
.wrapper.style1 button.special,
.wrapper.style1 .button.special {
  box-shadow: none;
  background-color: #ffffff;
  color: #FD81B1;
}

.wrapper.style1 input[type="submit"].special.icon:before,
.wrapper.style1 input[type="reset"].special.icon:before,
.wrapper.style1 input[type="button"].special.icon:before,
.wrapper.style1 button.special.icon:before,
.wrapper.style1 .button.special.icon:before {
  color: #FD81B1;
}

.wrapper.style1 label {
  color: #ffffff;
}

.wrapper.style1 input[type="text"],
.wrapper.style1 input[type="password"],
.wrapper.style1 input[type="email"],
.wrapper.style1 select,
.wrapper.style1 textarea {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 input[type="text"]:focus,
.wrapper.style1 input[type="password"]:focus,
.wrapper.style1 input[type="email"]:focus,
.wrapper.style1 select:focus,
.wrapper.style1 textarea:focus {
  border-color: #ffffff;
}

.wrapper.style1 input[type="text"].alt,
.wrapper.style1 input[type="password"].alt,
.wrapper.style1 input[type="email"].alt,
.wrapper.style1 select.alt,
.wrapper.style1 textarea.alt {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #FD81B1;
}

.wrapper.style1 input[type="text"].alt:focus,
.wrapper.style1 input[type="password"].alt:focus,
.wrapper.style1 input[type="email"].alt:focus,
.wrapper.style1 select.alt:focus,
.wrapper.style1 textarea.alt:focus {
  border-color: #fecde0;
}

.wrapper.style1 .select-wrapper:before {
  color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 input[type="checkbox"] + label,
.wrapper.style1 input[type="radio"] + label {
  color: #ffe0ec;
}

.wrapper.style1 input[type="checkbox"] + label:before,
.wrapper.style1 input[type="radio"] + label:before {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 input[type="checkbox"]:checked + label:before,
.wrapper.style1 input[type="radio"]:checked + label:before {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #FD81B1;
}

.wrapper.style1 input[type="checkbox"]:focus + label:before,
.wrapper.style1 input[type="radio"]:focus + label:before {
  border-color: #ffffff;
}

.wrapper.style1 ::-webkit-input-placeholder {
  color: #fecde0 !important;
}

.wrapper.style1 .alt::-webkit-input-placeholder {
  color: #bbb !important;
}

.wrapper.style1 :-moz-placeholder {
  color: #fecde0 !important;
}

.wrapper.style1 .alt:-moz-placeholder {
  color: #bbb !important;
}

.wrapper.style1 ::-moz-placeholder {
  color: #fecde0 !important;
}

.wrapper.style1 .alt::-moz-placeholder {
  color: #bbb !important;
}

.wrapper.style1 :-ms-input-placeholder {
  color: #fecde0 !important;
}

.wrapper.style1 .alt:-ms-input-placeholder {
  color: #bbb !important;
}

.wrapper.style1 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 header p {
  color: #fecde0;
}

.wrapper.style1 table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style1 table th {
  color: #ffffff;
}

.wrapper.style1 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 {
  background-color: #00BEE6;
  color: #bfeff9;
}

.wrapper.style2 input, .wrapper.style2 select, .wrapper.style2 textarea {
  color: #ffffff;
}

.wrapper.style2 a {
  color: #FD81B1;
}

.wrapper.style2 strong, .wrapper.style2 b {
  color: #ffffff;
}

.wrapper.style2 h1, .wrapper.style2 h2, .wrapper.style2 h3, .wrapper.style2 h4, .wrapper.style2 h5, .wrapper.style2 h6 {
  color: #ffffff;
}

.wrapper.style2 blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 .box {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 input[type="submit"],
.wrapper.style2 input[type="reset"],
.wrapper.style2 input[type="button"],
.wrapper.style2 button,
.wrapper.style2 .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.25);
  color: #ffffff;
}

.wrapper.style2 input[type="submit"]:hover, .wrapper.style2 input[type="submit"]:focus,
.wrapper.style2 input[type="reset"]:hover,
.wrapper.style2 input[type="reset"]:focus,
.wrapper.style2 input[type="button"]:hover,
.wrapper.style2 input[type="button"]:focus,
.wrapper.style2 button:hover,
.wrapper.style2 button:focus,
.wrapper.style2 .button:hover,
.wrapper.style2 .button:focus {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style2 input[type="submit"]:active,
.wrapper.style2 input[type="reset"]:active,
.wrapper.style2 input[type="button"]:active,
.wrapper.style2 button:active,
.wrapper.style2 .button:active {
  background-color: rgba(255, 255, 255, 0.175);
}

.wrapper.style2 input[type="submit"].icon:before,
.wrapper.style2 input[type="reset"].icon:before,
.wrapper.style2 input[type="button"].icon:before,
.wrapper.style2 button.icon:before,
.wrapper.style2 .button.icon:before {
  color: #99e5f5;
}

.wrapper.style2 input[type="submit"].special,
.wrapper.style2 input[type="reset"].special,
.wrapper.style2 input[type="button"].special,
.wrapper.style2 button.special,
.wrapper.style2 .button.special {
  box-shadow: none;
  background-color: #FD81B1;
  color: #ffffff;
}

.wrapper.style2 input[type="submit"].special.icon:before,
.wrapper.style2 input[type="reset"].special.icon:before,
.wrapper.style2 input[type="button"].special.icon:before,
.wrapper.style2 button.special.icon:before,
.wrapper.style2 .button.special.icon:before {
  color: #ffffff;
}

.wrapper.style2 input[type="submit"].special:hover, .wrapper.style2 input[type="submit"].special:focus,
.wrapper.style2 input[type="reset"].special:hover,
.wrapper.style2 input[type="reset"].special:focus,
.wrapper.style2 input[type="button"].special:hover,
.wrapper.style2 input[type="button"].special:focus,
.wrapper.style2 button.special:hover,
.wrapper.style2 button.special:focus,
.wrapper.style2 .button.special:hover,
.wrapper.style2 .button.special:focus {
  background-color: #fd9ac1;
}

.wrapper.style2 input[type="submit"].special:active,
.wrapper.style2 input[type="reset"].special:active,
.wrapper.style2 input[type="button"].special:active,
.wrapper.style2 button.special:active,
.wrapper.style2 .button.special:active {
  background-color: #fd68a1;
}

.wrapper.style2 label {
  color: #ffffff;
}

.wrapper.style2 input[type="text"],
.wrapper.style2 input[type="password"],
.wrapper.style2 input[type="email"],
.wrapper.style2 select,
.wrapper.style2 textarea {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 input[type="text"]:focus,
.wrapper.style2 input[type="password"]:focus,
.wrapper.style2 input[type="email"]:focus,
.wrapper.style2 select:focus,
.wrapper.style2 textarea:focus {
  border-color: #FD81B1;
}

.wrapper.style2 input[type="text"].alt,
.wrapper.style2 input[type="password"].alt,
.wrapper.style2 input[type="email"].alt,
.wrapper.style2 select.alt,
.wrapper.style2 textarea.alt {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #00BEE6;
}

.wrapper.style2 input[type="text"].alt:focus,
.wrapper.style2 input[type="password"].alt:focus,
.wrapper.style2 input[type="email"].alt:focus,
.wrapper.style2 select.alt:focus,
.wrapper.style2 textarea.alt:focus {
  border-color: #99e5f5;
}

.wrapper.style2 .select-wrapper:before {
  color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 input[type="checkbox"] + label,
.wrapper.style2 input[type="radio"] + label {
  color: #bfeff9;
}

.wrapper.style2 input[type="checkbox"] + label:before,
.wrapper.style2 input[type="radio"] + label:before {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 input[type="checkbox"]:checked + label:before,
.wrapper.style2 input[type="radio"]:checked + label:before {
  background-color: #FD81B1;
  border-color: #FD81B1;
  color: #ffffff;
}

.wrapper.style2 input[type="checkbox"]:focus + label:before,
.wrapper.style2 input[type="radio"]:focus + label:before {
  border-color: #FD81B1;
}

.wrapper.style2 ::-webkit-input-placeholder {
  color: #99e5f5 !important;
}

.wrapper.style2 .alt::-webkit-input-placeholder {
  color: #bbb !important;
}

.wrapper.style2 :-moz-placeholder {
  color: #99e5f5 !important;
}

.wrapper.style2 .alt:-moz-placeholder {
  color: #bbb !important;
}

.wrapper.style2 ::-moz-placeholder {
  color: #99e5f5 !important;
}

.wrapper.style2 .alt::-moz-placeholder {
  color: #bbb !important;
}

.wrapper.style2 :-ms-input-placeholder {
  color: #99e5f5 !important;
}

.wrapper.style2 .alt:-ms-input-placeholder {
  color: #bbb !important;
}

.wrapper.style2 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 header p {
  color: #99e5f5;
}

.wrapper.style2 table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style2 table th {
  color: #ffffff;
}

.wrapper.style2 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 {
  background-color: #594095;
  color: #d6cfe5;
}

.wrapper.style3 input, .wrapper.style3 select, .wrapper.style3 textarea {
  color: #ffffff;
}

.wrapper.style3 a {
  color: #FD81B1;
}

.wrapper.style3 strong, .wrapper.style3 b {
  color: #ffffff;
}

.wrapper.style3 h1, .wrapper.style3 h2, .wrapper.style3 h3, .wrapper.style3 h4, .wrapper.style3 h5, .wrapper.style3 h6 {
  color: #ffffff;
}

.wrapper.style3 blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 .box {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="submit"],
.wrapper.style3 input[type="reset"],
.wrapper.style3 input[type="button"],
.wrapper.style3 button,
.wrapper.style3 .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.25);
  color: #ffffff;
}

.wrapper.style3 input[type="submit"]:hover, .wrapper.style3 input[type="submit"]:focus,
.wrapper.style3 input[type="reset"]:hover,
.wrapper.style3 input[type="reset"]:focus,
.wrapper.style3 input[type="button"]:hover,
.wrapper.style3 input[type="button"]:focus,
.wrapper.style3 button:hover,
.wrapper.style3 button:focus,
.wrapper.style3 .button:hover,
.wrapper.style3 .button:focus {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style3 input[type="submit"]:active,
.wrapper.style3 input[type="reset"]:active,
.wrapper.style3 input[type="button"]:active,
.wrapper.style3 button:active,
.wrapper.style3 .button:active {
  background-color: rgba(255, 255, 255, 0.175);
}

.wrapper.style3 input[type="submit"].icon:before,
.wrapper.style3 input[type="reset"].icon:before,
.wrapper.style3 input[type="button"].icon:before,
.wrapper.style3 button.icon:before,
.wrapper.style3 .button.icon:before {
  color: #bdb3d5;
}

.wrapper.style3 input[type="submit"].special,
.wrapper.style3 input[type="reset"].special,
.wrapper.style3 input[type="button"].special,
.wrapper.style3 button.special,
.wrapper.style3 .button.special {
  box-shadow: none;
  background-color: #FD81B1;
  color: #ffffff;
}

.wrapper.style3 input[type="submit"].special.icon:before,
.wrapper.style3 input[type="reset"].special.icon:before,
.wrapper.style3 input[type="button"].special.icon:before,
.wrapper.style3 button.special.icon:before,
.wrapper.style3 .button.special.icon:before {
  color: #ffffff;
}

.wrapper.style3 input[type="submit"].special:hover, .wrapper.style3 input[type="submit"].special:focus,
.wrapper.style3 input[type="reset"].special:hover,
.wrapper.style3 input[type="reset"].special:focus,
.wrapper.style3 input[type="button"].special:hover,
.wrapper.style3 input[type="button"].special:focus,
.wrapper.style3 button.special:hover,
.wrapper.style3 button.special:focus,
.wrapper.style3 .button.special:hover,
.wrapper.style3 .button.special:focus {
  background-color: #fd9ac1;
}

.wrapper.style3 input[type="submit"].special:active,
.wrapper.style3 input[type="reset"].special:active,
.wrapper.style3 input[type="button"].special:active,
.wrapper.style3 button.special:active,
.wrapper.style3 .button.special:active {
  background-color: #fd68a1;
}

.wrapper.style3 label {
  color: #ffffff;
}

.wrapper.style3 input[type="text"],
.wrapper.style3 input[type="password"],
.wrapper.style3 input[type="email"],
.wrapper.style3 select,
.wrapper.style3 textarea {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="text"]:focus,
.wrapper.style3 input[type="password"]:focus,
.wrapper.style3 input[type="email"]:focus,
.wrapper.style3 select:focus,
.wrapper.style3 textarea:focus {
  border-color: #FD81B1;
}

.wrapper.style3 input[type="text"].alt,
.wrapper.style3 input[type="password"].alt,
.wrapper.style3 input[type="email"].alt,
.wrapper.style3 select.alt,
.wrapper.style3 textarea.alt {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #594095;
}

.wrapper.style3 input[type="text"].alt:focus,
.wrapper.style3 input[type="password"].alt:focus,
.wrapper.style3 input[type="email"].alt:focus,
.wrapper.style3 select.alt:focus,
.wrapper.style3 textarea.alt:focus {
  border-color: #bdb3d5;
}

.wrapper.style3 .select-wrapper:before {
  color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="checkbox"] + label,
.wrapper.style3 input[type="radio"] + label {
  color: #d6cfe5;
}

.wrapper.style3 input[type="checkbox"] + label:before,
.wrapper.style3 input[type="radio"] + label:before {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="checkbox"]:checked + label:before,
.wrapper.style3 input[type="radio"]:checked + label:before {
  background-color: #FD81B1;
  border-color: #FD81B1;
  color: #ffffff;
}

.wrapper.style3 input[type="checkbox"]:focus + label:before,
.wrapper.style3 input[type="radio"]:focus + label:before {
  border-color: #FD81B1;
}

.wrapper.style3 ::-webkit-input-placeholder {
  color: #bdb3d5 !important;
}

.wrapper.style3 .alt::-webkit-input-placeholder {
  color: #bbb !important;
}

.wrapper.style3 :-moz-placeholder {
  color: #bdb3d5 !important;
}

.wrapper.style3 .alt:-moz-placeholder {
  color: #bbb !important;
}

.wrapper.style3 ::-moz-placeholder {
  color: #bdb3d5 !important;
}

.wrapper.style3 .alt::-moz-placeholder {
  color: #bbb !important;
}

.wrapper.style3 :-ms-input-placeholder {
  color: #bdb3d5 !important;
}

.wrapper.style3 .alt:-ms-input-placeholder {
  color: #bbb !important;
}

.wrapper.style3 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 header p {
  color: #bdb3d5;
}

.wrapper.style3 table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style3 table th {
  color: #ffffff;
}

.wrapper.style3 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 {
  background-color: #6E81D0;
  color: #dbe0f3;
}

.wrapper.style4 input, .wrapper.style4 select, .wrapper.style4 textarea {
  color: #ffffff;
}

.wrapper.style4 a {
  color: #FD81B1;
}

.wrapper.style4 strong, .wrapper.style4 b {
  color: #ffffff;
}

.wrapper.style4 h1, .wrapper.style4 h2, .wrapper.style4 h3, .wrapper.style4 h4, .wrapper.style4 h5, .wrapper.style4 h6 {
  color: #ffffff;
}

.wrapper.style4 blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 .box {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 input[type="submit"],
.wrapper.style4 input[type="reset"],
.wrapper.style4 input[type="button"],
.wrapper.style4 button,
.wrapper.style4 .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.25);
  color: #ffffff;
}

.wrapper.style4 input[type="submit"]:hover, .wrapper.style4 input[type="submit"]:focus,
.wrapper.style4 input[type="reset"]:hover,
.wrapper.style4 input[type="reset"]:focus,
.wrapper.style4 input[type="button"]:hover,
.wrapper.style4 input[type="button"]:focus,
.wrapper.style4 button:hover,
.wrapper.style4 button:focus,
.wrapper.style4 .button:hover,
.wrapper.style4 .button:focus {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style4 input[type="submit"]:active,
.wrapper.style4 input[type="reset"]:active,
.wrapper.style4 input[type="button"]:active,
.wrapper.style4 button:active,
.wrapper.style4 .button:active {
  background-color: rgba(255, 255, 255, 0.175);
}

.wrapper.style4 input[type="submit"].icon:before,
.wrapper.style4 input[type="reset"].icon:before,
.wrapper.style4 input[type="button"].icon:before,
.wrapper.style4 button.icon:before,
.wrapper.style4 .button.icon:before {
  color: #c5cdec;
}

.wrapper.style4 input[type="submit"].special,
.wrapper.style4 input[type="reset"].special,
.wrapper.style4 input[type="button"].special,
.wrapper.style4 button.special,
.wrapper.style4 .button.special {
  box-shadow: none;
  background-color: #FD81B1;
  color: #ffffff;
}

.wrapper.style4 input[type="submit"].special.icon:before,
.wrapper.style4 input[type="reset"].special.icon:before,
.wrapper.style4 input[type="button"].special.icon:before,
.wrapper.style4 button.special.icon:before,
.wrapper.style4 .button.special.icon:before {
  color: #ffffff;
}

.wrapper.style4 input[type="submit"].special:hover, .wrapper.style4 input[type="submit"].special:focus,
.wrapper.style4 input[type="reset"].special:hover,
.wrapper.style4 input[type="reset"].special:focus,
.wrapper.style4 input[type="button"].special:hover,
.wrapper.style4 input[type="button"].special:focus,
.wrapper.style4 button.special:hover,
.wrapper.style4 button.special:focus,
.wrapper.style4 .button.special:hover,
.wrapper.style4 .button.special:focus {
  background-color: #fd9ac1;
}

.wrapper.style4 input[type="submit"].special:active,
.wrapper.style4 input[type="reset"].special:active,
.wrapper.style4 input[type="button"].special:active,
.wrapper.style4 button.special:active,
.wrapper.style4 .button.special:active {
  background-color: #fd68a1;
}

.wrapper.style4 label {
  color: #ffffff;
}

.wrapper.style4 input[type="text"],
.wrapper.style4 input[type="password"],
.wrapper.style4 input[type="email"],
.wrapper.style4 select,
.wrapper.style4 textarea {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 input[type="text"]:focus,
.wrapper.style4 input[type="password"]:focus,
.wrapper.style4 input[type="email"]:focus,
.wrapper.style4 select:focus,
.wrapper.style4 textarea:focus {
  border-color: #FD81B1;
}

.wrapper.style4 input[type="text"].alt,
.wrapper.style4 input[type="password"].alt,
.wrapper.style4 input[type="email"].alt,
.wrapper.style4 select.alt,
.wrapper.style4 textarea.alt {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #6E81D0;
}

.wrapper.style4 input[type="text"].alt:focus,
.wrapper.style4 input[type="password"].alt:focus,
.wrapper.style4 input[type="email"].alt:focus,
.wrapper.style4 select.alt:focus,
.wrapper.style4 textarea.alt:focus {
  border-color: #c5cdec;
}

.wrapper.style4 .select-wrapper:before {
  color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 input[type="checkbox"] + label,
.wrapper.style4 input[type="radio"] + label {
  color: #dbe0f3;
}

.wrapper.style4 input[type="checkbox"] + label:before,
.wrapper.style4 input[type="radio"] + label:before {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 input[type="checkbox"]:checked + label:before,
.wrapper.style4 input[type="radio"]:checked + label:before {
  background-color: #FD81B1;
  border-color: #FD81B1;
  color: #ffffff;
}

.wrapper.style4 input[type="checkbox"]:focus + label:before,
.wrapper.style4 input[type="radio"]:focus + label:before {
  border-color: #FD81B1;
}

.wrapper.style4 ::-webkit-input-placeholder {
  color: #c5cdec !important;
}

.wrapper.style4 .alt::-webkit-input-placeholder {
  color: #bbb !important;
}

.wrapper.style4 :-moz-placeholder {
  color: #c5cdec !important;
}

.wrapper.style4 .alt:-moz-placeholder {
  color: #bbb !important;
}

.wrapper.style4 ::-moz-placeholder {
  color: #c5cdec !important;
}

.wrapper.style4 .alt::-moz-placeholder {
  color: #bbb !important;
}

.wrapper.style4 :-ms-input-placeholder {
  color: #c5cdec !important;
}

.wrapper.style4 .alt:-ms-input-placeholder {
  color: #bbb !important;
}

.wrapper.style4 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 header p {
  color: #c5cdec;
}

.wrapper.style4 table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style4 table th {
  color: #ffffff;
}

.wrapper.style4 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style4 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}

@media screen and (max-width: 1280px) {
  .wrapper {
    padding: 5rem 0 2.5rem 0 ;
  }
}

@media screen and (max-width: 980px) {
  .wrapper {
    padding: 4rem 0 1.5rem 0 ;
  }
}

@media screen and (max-width: 736px) {
  .wrapper {
    padding: 3rem 0 0.5rem 0 ;
  }
  .wrapper > .inner {
    width: calc(100% - 3rem);
  }
}

/* Carousel */
.page-slider article {
  width: 100%;
}

.wrapper.style4 .slick-prev,
.wrapper.style4 .slick-next {
  box-shadow: none;
}

.wrapper.style4 .slick-prev:before,
.wrapper.style4 .slick-next:before {
  color: #fff;
}

.carousel {
  position: relative;
  margin: 0 0 2.5rem 0;
  /*
		@include breakpoint(small) {
			article {
				@include vendor('transform', 'translateY(0)');
				display: block !important;
				opacity: 1;
				visibility: visible;
				width: 100%;
				margin: 2em auto 0 auto;

				&:first-child {
					margin-top: 0;
				}

				.image {
					height: 15rem;
				}

				.content {
					@include padding(2rem, 1.5rem);
				}
			}

			nav {
				display: none;
			}
		}
		*/
}

@media screen and (max-width: 980px) {
  .carousel {
    padding: 0 10px;
  }
}

.carousel .carousel-image {
  flex-basis: 30%;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 736px) {
  .carousel .carousel-image {
    display: none;
  }
}

.carousel .t-name {
  font-style: italic;
  margin-bottom: 20px;
}

.carousel article {
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex !important;
}

@media screen and (max-width: 736px) {
  .carousel article {
    display: block !important;
  }
}

.carousel article .content {
  padding: 5rem 5rem 2.5rem 5rem ;
  color: #888 !important;
  flex: 1;
}

.carousel article .content h3 {
  color: #666666 !important;
}

@media screen and (max-width: 1280px) {
  .carousel article {
    width: calc(100% - 4rem);
  }
  .carousel article .image {
    height: 20rem;
  }
  .carousel article .content {
    padding: 4rem 4rem 1.5rem 4rem ;
  }
  .carousel article .content br {
    display: none;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  box-shadow: none;
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #FD81B1;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

@media screen and (max-width: 980px) {
  .slick-prev {
    left: -15px;
  }
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

@media screen and (max-width: 980px) {
  .slick-next {
    right: -15px;
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Spotlight */
.spotlight {
  padding: 7rem 0;
  position: relative;
}

.spotlight > .inner {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 75rem;
  width: calc(100% - 4rem);
}

.spotlight h2 {
  -moz-transition: opacity 0.5s ease-out, -moz-transform 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
  -ms-transition: opacity 0.5s ease-out, -ms-transform 0.5s ease-out;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  margin-bottom: 0;
}

.spotlight .image {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 45%;
}

.spotlight .image img {
  -moz-object-fit: cover;
  -webkit-object-fit: cover;
  -ms-object-fit: cover;
  object-fit: cover;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spotlight .content {
  padding: 5rem 5rem 2.5rem 5rem ;
  -moz-transition: opacity 0.5s ease-out, -moz-transform 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
  -ms-transition: opacity 0.5s ease-out, -ms-transform 0.5s ease-out;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  background-color: #fff;
  position: relative;
  z-index: 1;
  width: 55%;
}

.spotlight.odd .image {
  right: 0;
}

.spotlight.even > .inner {
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.spotlight.even .image {
  left: 0;
}

.spotlight.even h2 {
  text-align: right;
}

.spotlight.accent1 {
  background-color: #FD81B1;
}

.spotlight.accent1 h2 {
  color: #ffffff;
}

.spotlight.accent2 {
  background-color: #00BEE6;
}

.spotlight.accent2 h2 {
  color: #ffffff;
}

.spotlight.accent3 {
  background-color: #594095;
}

.spotlight.accent3 h2 {
  color: #ffffff;
}

.spotlight.accent4 {
  background-color: #6E81D0;
}

.spotlight.accent4 h2 {
  color: #ffffff;
}

.spotlight.is-inactive h2 {
  -moz-transform: translateY(-2rem);
  -webkit-transform: translateY(-2rem);
  -ms-transform: translateY(-2rem);
  transform: translateY(-2rem);
  opacity: 0;
}

.spotlight.is-inactive .content {
  -moz-transform: translateY(2rem);
  -webkit-transform: translateY(2rem);
  -ms-transform: translateY(2rem);
  transform: translateY(2rem);
  opacity: 0;
}

@media screen and (max-width: 1280px) {
  .spotlight {
    padding: 5.5rem 0;
  }
  .spotlight > .inner {
    -moz-justify-content: -moz-flex-start;
    -webkit-justify-content: -webkit-flex-start;
    -ms-justify-content: -ms-flex-start;
    justify-content: flex-start;
  }
  .spotlight.even h2 {
    text-align: left;
  }
  .spotlight h2 {
    padding: 0 3rem;
    width: 40%;
    text-align: center;
  }
  .spotlight .content {
    padding: 4rem 4rem 1.5rem 4rem ;
    width: 45%;
  }
}

@media screen and (max-width: 980px) {
  .spotlight {
    padding: 0;
    text-align: center;
  }
  .spotlight.even h2 {
    text-align: center;
  }
  .spotlight > .inner {
    display: block;
    max-width: none;
    width: 100%;
  }
  .spotlight h2 {
    padding: 2.5rem 2.5rem;
    width: 100%;
  }
  .spotlight h2 br {
    display: none;
  }
  .spotlight .image {
    display: block;
    height: 40vh;
    min-height: 20rem;
    width: 100%;
    position: relative;
  }
  .spotlight .content {
    padding: 3rem 2.5rem 0.5rem 2.5rem ;
    border-radius: 0;
    width: 100%;
  }
  .spotlight .content h3 br {
    display: none;
  }
  .spotlight.is-inactive h2 {
    -moz-transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 0;
  }
  .spotlight.is-inactive .content {
    -moz-transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 0;
  }
}

@media screen and (max-width: 736px) {
  .spotlight h2 {
    padding: 1.5rem;
  }
  .spotlight .content {
    padding: 2rem 1.5rem 0.1rem 1.5rem ;
  }
}

/* Testimonials */
.t-quote {
  font-size: 200px;
  line-height: 0.3;
}

@media screen and (max-width: 980px) {
  .t-quote {
    font-size: 80px;
    line-height: 0.5;
  }
}

.t-name {
  font-style: italic;
}

.testimonial {
  display: flex;
  position: relative;
  color: #fff;
}

@media screen and (max-width: 736px) {
  .testimonial {
    flex-direction: column;
  }
}

.testimonial.even {
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  text-align: right;
}

.testimonial .content {
  flex: 1;
  padding: 60px;
}

@media screen and (max-width: 980px) {
  .testimonial .content {
    padding: 20px;
  }
}

.testimonial .content p {
  font-size: 18px;
}

.testimonial h3 {
  color: #fff;
}

.testimonial .testimonial-image {
  flex-basis: 45%;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 736px) {
  .testimonial .testimonial-image {
    min-height: 300px;
  }
}

.testimonial.accent1 {
  background-color: #FD81B1;
}

.testimonial.accent1 h2 {
  color: #ffffff;
}

.testimonial.accent2 {
  background-color: #00BEE6;
}

.testimonial.accent2 h2 {
  color: #ffffff;
}

.testimonial.accent3 {
  background-color: #594095;
}

.testimonial.accent3 h2 {
  color: #ffffff;
}

.testimonial.accent4 {
  background-color: #6E81D0;
}

.testimonial.accent4 h2 {
  color: #ffffff;
}

img[data-action="zoom"] {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}

.zoom-img,
.zoom-img-wrap {
  position: relative;
  z-index: 666;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

img.zoom-img {
  cursor: pointer;
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
}

.zoom-overlay {
  z-index: 420;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  filter: "alpha(opacity=0)";
  opacity: 0;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms;
}

.zoom-overlay-open .zoom-overlay {
  filter: "alpha(opacity=100)";
  opacity: 1;
}

.zoom-overlay-open,
.zoom-overlay-transitioning {
  cursor: default;
}

/* Header */
#header {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-transition: background-color 0.2s ease;
  -webkit-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  background-color: #fff;
  box-shadow: 0 0 0.5rem 0 rgba(144, 144, 144, 0.25);
  height: 4.5rem;
  left: 0;
  line-height: 4.5rem;
  padding: 0 0 0 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

#header a {
  color: #666666;
  font-size: 1.1rem;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
}

#header a:active, #header a:hover, #header a:focus {
  color: #FD81B1;
}

#header .logo {
  display: block;
  font-weight: 700;
  height: inherit;
  letter-spacing: 0.07rem;
  line-height: inherit;
  padding: 5px 0;
  width: 160px;
}

#header .logo span {
  font-weight: 400;
}

#header nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#header nav ul li {
  display: inline-block;
  padding: 0;
}

#header nav ul li a {
  display: inline-block;
  padding: 0 1.5rem 0 1.5rem;
  position: relative;
}

#header nav ul li a[href="#menu"] {
  text-decoration: none;
  padding-right: 3rem;
}

#header nav ul li a[href="#menu"]:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Pro;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

#header nav ul li a[href="#menu"]:before {
  content: '\f0c9';
  display: block;
  height: 100%;
  position: absolute;
  right: 1.5rem;
  text-align: center;
  top: 0;
  width: 1rem;
}

#header.alt {
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}

#header.alt .logofill {
  fill: #fff !important;
}

#header.alt a {
  color: #fff;
}

@media screen and (max-width: 736px) {
  #header {
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 0 0 0.75rem;
  }
  #header nav ul li a {
    padding: 0 0.75rem 0 0.75rem;
  }
  #header nav ul li a[href="#menu"] {
    padding-right: 2.5rem;
  }
  #header nav ul li a[href="#menu"]:before {
    right: 1rem;
  }
}

@media screen and (max-width: 480px) {
  #header .logo span {
    display: none;
  }
  #header nav ul li a[href="#menu"]:only-child {
    width: 4rem;
    text-indent: 4rem;
    white-space: nowrap;
  }
  #header nav ul li a[href="#menu"]:only-child:before {
    text-indent: 0;
  }
}

/* Menu */
#menu {
  background-color: #FD81B1;
  color: #ffe0ec;
  -moz-transform: translateX(20rem);
  -webkit-transform: translateX(20rem);
  -ms-transform: translateX(20rem);
  transform: translateX(20rem);
  -moz-transition: -moz-transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
  -webkit-transition: -webkit-transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
  -ms-transition: -ms-transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
  transition: transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
  -webkit-overflow-scrolling: touch;
  background: #f96ca2;
  box-shadow: none;
  height: 100%;
  max-width: 80%;
  overflow-y: auto;
  padding: 3rem 2rem;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 20rem;
  z-index: 10002;
}

#menu input, #menu select, #menu textarea {
  color: #ffffff;
}

#menu a {
  color: #ffffff;
}

#menu strong, #menu b {
  color: #ffffff;
}

#menu h1, #menu h2, #menu h3, #menu h4, #menu h5, #menu h6 {
  color: #ffffff;
}

#menu blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

#menu code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

#menu hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

#menu input[type="submit"],
#menu input[type="reset"],
#menu input[type="button"],
#menu button,
#menu .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.25);
  color: #ffffff;
}

#menu input[type="submit"]:hover, #menu input[type="submit"]:focus,
#menu input[type="reset"]:hover,
#menu input[type="reset"]:focus,
#menu input[type="button"]:hover,
#menu input[type="button"]:focus,
#menu button:hover,
#menu button:focus,
#menu .button:hover,
#menu .button:focus {
  background-color: rgba(255, 255, 255, 0.075);
}

#menu input[type="submit"]:active,
#menu input[type="reset"]:active,
#menu input[type="button"]:active,
#menu button:active,
#menu .button:active {
  background-color: rgba(255, 255, 255, 0.175);
}

#menu input[type="submit"].icon:before,
#menu input[type="reset"].icon:before,
#menu input[type="button"].icon:before,
#menu button.icon:before,
#menu .button.icon:before {
  color: #fecde0;
}

#menu input[type="submit"].special,
#menu input[type="reset"].special,
#menu input[type="button"].special,
#menu button.special,
#menu .button.special {
  box-shadow: none;
  background-color: #ffffff;
  color: #FD81B1;
}

#menu input[type="submit"].special.icon:before,
#menu input[type="reset"].special.icon:before,
#menu input[type="button"].special.icon:before,
#menu button.special.icon:before,
#menu .button.special.icon:before {
  color: #FD81B1;
}

#menu > ul {
  margin: 0 0 1.25rem 0;
}

#menu > ul.links {
  list-style: none;
  padding: 0;
}

#menu > ul.links > li {
  padding: 0;
}

#menu > ul.links > li > a:not(.button) {
  border: 0;
  border-top: solid 1px rgba(255, 255, 255, 0.25);
  color: inherit;
  display: block;
  line-height: 3.5rem;
  text-decoration: none;
}

#menu > ul.links > li > a:not(.button):hover {
  color: #fff;
}

#menu > ul.links > li > .button {
  display: block;
  margin: 1.25rem 0 0 0;
}

#menu > ul.links > li:first-child > a:not(.button) {
  border-top: 0 !important;
}

#menu > ul.links > li ul {
  list-style: none;
}

#menu > ul.links > li ul li:before {
  font: normal normal normal 14px/1 Font Awesome\ 5 Pro;
  content: "\f0da";
}

#menu > ul.links > li ul li > a {
  color: inherit;
  text-decoration: none;
}

#menu > ul.links > li ul li > a:hover {
  color: #fff;
}

#menu .close {
  text-decoration: none;
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  height: 3.25rem;
  line-height: 3.25rem;
  padding-right: 1.25rem;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  vertical-align: middle;
  width: 7rem;
}

#menu .close:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Pro;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

#menu .close:before {
  content: '\f00d';
  font-size: 1.25rem;
}

#menu .close:hover {
  color: #ffffff;
}

@media screen and (max-width: 736px) {
  #menu .close {
    height: 4rem;
    line-height: 4rem;
  }
}

#menu.visible {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
  visibility: visible;
}

@media screen and (max-width: 736px) {
  #menu {
    padding: 2.5rem 1.75rem;
  }
}

/* Banner */
#banner {
  padding: 6rem 8% 3.5rem 8% ;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: #FD81B1;
  color: #ffffff;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  cursor: default;
  min-height: 100vh;
  position: relative;
  text-align: center;
}

#banner input, #banner select, #banner textarea {
  color: #ffffff;
}

#banner a {
  color: #FD81B1;
}

#banner strong, #banner b {
  color: #ffffff;
}

#banner h1, #banner h2, #banner h3, #banner h4, #banner h5, #banner h6 {
  color: #ffffff;
}

#banner blockquote {
  border-left-color: #ffffff;
}

#banner code {
  background: rgba(255, 255, 255, 0.075);
  border-color: #ffffff;
}

#banner hr {
  border-bottom-color: #ffffff;
}

#banner input[type="submit"],
#banner input[type="reset"],
#banner input[type="button"],
#banner button,
#banner .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 3px #ffffff;
  color: #ffffff;
}

#banner input[type="submit"]:hover, #banner input[type="submit"]:focus,
#banner input[type="reset"]:hover,
#banner input[type="reset"]:focus,
#banner input[type="button"]:hover,
#banner input[type="button"]:focus,
#banner button:hover,
#banner button:focus,
#banner .button:hover,
#banner .button:focus {
  background-color: rgba(255, 255, 255, 0.075);
}

#banner input[type="submit"]:active,
#banner input[type="reset"]:active,
#banner input[type="button"]:active,
#banner button:active,
#banner .button:active {
  background-color: rgba(255, 255, 255, 0.175);
}

#banner input[type="submit"].icon:before,
#banner input[type="reset"].icon:before,
#banner input[type="button"].icon:before,
#banner button.icon:before,
#banner .button.icon:before {
  color: #ffffff;
}

#banner input[type="submit"].special,
#banner input[type="reset"].special,
#banner input[type="button"].special,
#banner button.special,
#banner .button.special {
  box-shadow: none;
  background-color: #FD81B1;
  color: #ffffff;
}

#banner input[type="submit"].special.icon:before,
#banner input[type="reset"].special.icon:before,
#banner input[type="button"].special.icon:before,
#banner button.special.icon:before,
#banner .button.special.icon:before {
  color: #ffffff;
}

#banner input[type="submit"].special:hover, #banner input[type="submit"].special:focus,
#banner input[type="reset"].special:hover,
#banner input[type="reset"].special:focus,
#banner input[type="button"].special:hover,
#banner input[type="button"].special:focus,
#banner button.special:hover,
#banner button.special:focus,
#banner .button.special:hover,
#banner .button.special:focus {
  background-color: #fd9ac1;
}

#banner input[type="submit"].special:active,
#banner input[type="reset"].special:active,
#banner input[type="button"].special:active,
#banner button.special:active,
#banner .button.special:active {
  background-color: #fd68a1;
}

#banner:after {
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  -moz-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s;
  content: '';
  background-color: rgba(0, 0, 0, 0.4);
  /*background-color: _palette(alt, bg);*/
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#banner .inner {
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  position: relative;
  max-width: 35rem;
  z-index: 1;
}

#banner h1 {
  line-height: 1.3;
}

#banner h1:after {
  content: '';
  background-color: #ffffff;
  display: block;
  height: 3px;
  margin: 2rem auto;
  width: 4rem;
}

#banner p {
  margin-bottom: 2.5rem;
}

body.is-ie #banner {
  height: 100vh;
}

@media screen and (max-width: 1280px) {
  #banner {
    background-attachment: scroll;
  }
  #banner h1 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 736px) {
  #banner h1 {
    font-size: 2.5rem;
  }
  #banner h1 br {
    display: none;
  }
}

body.is-loading #banner:after {
  opacity: 1;
}

body.is-loading #banner .inner {
  opacity: 0;
}

/* Main */
.dontlikeyou {
  display: none;
}

.cc-compliance a {
  text-decoration: none;
}

.mc-flex {
  display: flex;
}

.mc-flex .flex-input {
  flex-basis: 30%;
  margin-right: 20px;
}

@media screen and (max-width: 980px) {
  .mc-flex .flex-input {
    flex-basis: 70%;
  }
}

@media screen and (max-width: 736px) {
  .mc-flex .flex-input {
    flex: 1;
  }
}

.mc-flex .flex-input input {
  color: #00BEE6;
}

.mc-flex input[type="submit"] {
  padding: 0 2.5em;
}

@media screen and (max-width: 736px) {
  .mc-flex input[type="submit"] {
    padding: 0 1em;
  }
}

.mc-privacy {
  font-size: 10px;
}

.mc-privacy a {
  color: #fff !important;
}

#main {
  padding-top: 7rem;
}

@media screen and (max-width: 980px) {
  #main {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 736px) {
  #main {
    padding-top: 4rem;
  }
}

.clear {
  clear: both;
}

.image-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: -4px;
}

.image-flex .image-child {
  flex: 1 1 30%;
  margin-bottom: 4px;
  margin-right: 4px;
}

.image-flex .image-child img {
  max-width: 100%;
  vertical-align: middle;
}

/* Footer */
#footer {
  padding: 6rem 3rem 3.5rem 3rem ;
  background-color: #f2f2f2;
}

#footer .icons {
  text-align: center;
}

#footer .copyright {
  color: #bbb;
  font-size: 0.9rem;
  margin: 0 0 2.5rem 0;
  padding: 0;
  text-align: center;
}

#footer .copyright li {
  border-left: solid 1px rgba(144, 144, 144, 0.25);
  display: inline-block;
  list-style: none;
  margin-left: 1.5rem;
  padding-left: 1.5rem;
}

#footer .copyright li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

#footer .icons .icon {
  color: #666666;
}

#footer .icons .icon:hover {
  color: #bbb;
}

#footer .contact-icons {
  text-align: center;
}

#footer .contact-icons li {
  display: inline-block;
  margin-left: 1rem;
}

#footer .contact-icons li:before {
  color: #666666;
  display: inline-block;
  font-size: 1.2rem;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
}

#footer .contact-icons li:first-child {
  margin-left: 0;
}

#footer .contact-icons a {
  text-decoration: none;
  color: #666666;
}

#footer .contact-icons a:active, #footer .contact-icons a:focus, #footer .contact-icons a:hover {
  color: #bbb;
}

@media screen and (max-width: 980px) {
  #footer {
    padding: 4rem 3rem 1.5rem 3rem ;
  }
  #footer .contact-icons {
    display: block;
  }
  #footer .contact-icons li {
    display: block;
    margin: 1rem 0 0 0;
  }
  #footer .contact-icons li:first-child {
    margin-top: 0;
  }
}

@media screen and (max-width: 736px) {
  #footer {
    padding: 3rem 2rem 0.5rem 2rem ;
  }
  #footer .copyright li {
    display: block;
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }
}
