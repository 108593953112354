/* Footer */

	#footer {
		@include padding(6rem, 3rem);
		background-color: #f2f2f2;

		.icons {
			text-align: center;
		}
		
		.copyright {
			color: _palette(fg-light);
			font-size: 0.9rem;
			margin: 0 0 _size(element-margin) 0;
			padding: 0;
			text-align: center;

			li {
				border-left: solid 1px _palette(border);
				display: inline-block;
				list-style: none;
				margin-left: 1.5rem;
				padding-left: 1.5rem;

				&:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		.icons {

			.icon {
				color: _palette(fg-bold);

				&:hover {
					color: _palette(fg-light);
				}
			
			}

		}

		.contact-icons {
			text-align: center;

			li {
				display: inline-block;
				margin-left: 1rem;

				&:before {
					$size: 2.5rem;
					color: _palette(fg-bold);
					display: inline-block;
					font-size: 1.2rem;
					height: $size;
					line-height: $size;
					width: $size;
				}

				&:first-child {
					margin-left: 0;
				}
			}

			a {
				text-decoration: none;
				color: _palette(fg-bold);

				&:active, &:focus, &:hover {
					color: _palette(fg-light);
				}
			}
		}

		@include breakpoint(medium) {
			@include padding(4rem, 3rem);

			.contact-icons {
				display: block;

				li {
					display: block;
					margin: 1rem 0 0 0;

					&:first-child {
						margin-top: 0;
					}
				}
			}
		}

		@include breakpoint(small) {
			@include padding(3rem, 2rem);

			.copyright {
				li {
					display: block;
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}
			}
		}
	}